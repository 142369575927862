import styled from 'styled-components'


export const Sessions = styled.div`
  text-align: center;
  margin: 32px;

  button {
    border: none;
    background: none;
    font-size: 18px;
    margin: 12px;
  }

  .session-selected {
    font-size: 19px;
    font-weight: 600;
    border-bottom: solid 2px ${props => props.brandcolor};
  `

export const Container = styled.div`
display: flex;
flex-direction: column;
height: 88%;
width: 100%;
overflow-x: hidden;
overflow-y: hidden;
margin-top: 40px;

`