import * as types from "../types";

export function socialLoginSubmit(payload) {
  return {
    type: types.SOCIAL_LOGIN_SUBMIT,
    payload,
  };
}

export function saveUserDataSubmit(payload) {
  return {
    type: types.SAVE_USER,
    payload,
  };
}

export function saveUserData(payload) {
  return {
    type: types.SAVE_USER_SUBMIT,
    payload,
  };
}

export function loginSuccess(payload) {
  return {
    type: types.LOGIN_SUCCESS,
    payload,
  };
}

export function loginFailure(payload) {
  return {
    type: types.LOGIN_FAILURE,
    payload,
  };
}

export function setOnboarding(payload) {
  return {
    type: types.SET_ONBOARDING,
    payload,
  };
}

export function updateUserPhone(payload) {
  return {
    type: types.UPDATE_USER_PHONE,
    payload,
  };
}
