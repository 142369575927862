import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as userRoomsActions from '../../../../store/modules/userRooms/actions'

// Styles
import { Container } from './styles'

// Components
import AllThumb from './components/AllThumb'

const AllList = (props) => {
    const [innerHeight, setInnerHeight] = useState(window.innerHeight)

    const isMyRoomsLoading = useSelector(state => state.userRoomsReducer.isLoading)
    const myRooms = useSelector(state => state.userRoomsReducer.rooms)

    const userId = useSelector(state => state.loginReducer.user.id)
    const contractId = useSelector(state => state.contractReducer.contract.contractId)
    const isContractLoading = useSelector(state => state.contractReducer.contract.isLoading)

    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    const dispatch = useDispatch()

    useEffect(() => {
        const handleResize = () => setInnerHeight(window.innerHeight)
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    useEffect(() => {
        (isMyRoomsLoading && !isContractLoading) && dispatch(userRoomsActions.getUserRooms({ "user": userId, "contract": contractId }))
    }, [isContractLoading, isMyRoomsLoading]);

    const onOrderThumbClick = (order) => {
        props.history.push({
            pathname: '/simulador',
            state: {
                roomModelId: order.room._id,
                playcanvasId: order.room.playCanvas,
                roomCameras: order.room.cameras,
                orderId: order._id,
                orderName: order.customer.name,
                orderPhone: order.customer.phone,
                orderTitle: order.title,
                orderStatus: order.status,
                orderImageKey: order.images[0].imageKey,
                showInspirationModal: false
            }
        })
    }


    return (
        <>
            <Container innerHeight={innerHeight} backgroundColor={backgroundColor} fontColor={fontColor}>
                <div className="room-lead">
                    <div className="lead-room" onClick={() => props.history.push('/novo-ambiente')}>
                        <i className="fas fa-plus-circle"></i>
                        <h2>Novo ambiente</h2>
                    </div>
                    {myRooms.length > 0 && <h3>Meus ambientes</h3>}
                    {myRooms.map(room => (
                        <AllThumb
                        id={room._id}
                        playCanvasId={room.PlaycanvasId}
                        title={room.title}
                        status={room.status}
                        name={room.customer.name}
                        imgSrc={room.images[0].imageSrc}
                        onThumbClick={() => onOrderThumbClick(room)}
                        ></AllThumb>
                    ))}
                </div>
            </Container>
        </>
    )
}

export default AllList