import styled from 'styled-components'

export const StylesList = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 76vw;
  padding-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(76vh - 150px);
  margin: 16px auto;

  div.divider {
    position: relative;
    flex-wrap: wrap;
    width: 76vw;
  }

  div h2 {
    color: ${props => props.brandColor};
    font-size: 20px;
    margin: 0 0 10px 16px;
    font-weight: 700;
    text-align: start;
    width: 100%;
  }

  ul,
  .slider {
    display: flex;
    height: auto;
    overflow: auto;
    margin-bottom: 32px;
    transition: transform 0.5s linear;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  li,
  .slide {
    margin: 0 1vw .2rem 1vw;
    border-radius: 8px;
    height: auto;
    cursor: pointer;
  }

  li:hover {
    opacity: .95;
  }

  li.active {
    z-index: 4;
  }

  li img {
    width: calc(68vw / 4);
    height: auto;
    border-radius: 8px;
  }

  button.button-back, 
  button.button-next {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    height: 32px;
    width: 32px;
    border-radius: 16px;
  }

  button.button-back:disabled, 
  button.button-next:disabled {
    opacity: .5 !important;
  }

  button.button-back:hover, 
  button.button-next:hover {
    opacity: .8;
  }

  button.button-back {
    left: 2px;
  }
 
  button.button-next {
    right: 2px;
  }
`