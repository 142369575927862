import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 37.5vw;
  width: 60vw;
  border-radius: 8px;
  background-color: transparent;
  margin: 0 .2rem 1.4rem .2rem;

  img {
    border-radius: 8px;
  }

  @media only screen and (min-width: 1024px) {
    width: 17.5vw;
    height: 12.125vw;
    margin: 0 .5vw .4rem .5vw;

    div.active {
      z-index: 5 !important;
    }

    img {
      width: 17.5vw !important;
      height: 12.125vw !important;
    }
  }


  .card-shadow {
    height: 70%;
    width: 100%;
    position: absolute;
    border-radius: 8px;
    background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.9));
    bottom: 0;
  }


  .editar {
    position: absolute;
    z-index: 10;
    right: 10px;
    bottom: 11px;
    color: white;
    cursor: pointer;
  }

  .editar:hover {
    opacity: .7;
  }

  h4 {
    position: absolute;
    z-index: 10;
    left: 10px;
    bottom: 11px;
    color: white;
    font-weight: normal;
  }


  .checkbox {
    position: absolute;
    z-index: 10;
    right: 10px;
    top: -10px;
    cursor: pointer;
  }

  .checkbox:hover {
    opacity: .7;
  }
  
  .checkbox input, .check input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkbox .checkmark, .check .checkmark {
    display: block;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 2rem;
  }
  
  .checkbox:hover input ~ .checkmark, .check:hover input ~ .checkmark {
   background-color: #ccc;
  }
  
  
  .checkbox input:checked ~ .checkmark, .check input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox input:checked ~ .checkmark:after, .check input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkbox .checkmark:after, .check .checkmark:after {
    left: 9px;
    top: 20px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
`