import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react'
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css';

import Routes from './routes/index'
import GlobalStyle from './styles/global'
import { persistor } from './store'

import ReactGA from 'react-ga'
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { AxiosComponent } from './services/api';

const App = () => {
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const [ready, setReady] = useState(false);

  useEffect(() => {

    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "f9419b17-31e4-4e58-bb56-49284d9909a8";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    ReactGA.initialize('UA-126220624-4')

  }, [])

  if (!ready) {
    setTimeout(function () {
      if (document.getElementsByClassName('crisp-client')[0])
        document.getElementsByClassName('crisp-client')[0].style.display = "block";
    }, 5000);
    setReady(true);
  }

  return (
    <>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
    
      <AxiosComponent>
        
      

        <PersistGate persistor={persistor}>
          <Routes />
          <GlobalStyle backgroundColor={backgroundColor} brandColor={brandColor} />
        </PersistGate>
  </AxiosComponent>

    </>
  )
}

export default App;
