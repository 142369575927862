import React, { useState, useEffect, useRef } from "react";
//import GoogleLogin from 'react-google-login'
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import api from "../../services/api";
import { DebounceInput } from "react-debounce-input";

import * as loginActions from "./../../store/modules/login/actions";
import * as contractActions from "./../../store/modules/contract/actions";
import * as userRoomsActions from "./../../store/modules/userRooms/actions";

// Styles
import { Container, Header } from "./styles";

import GenericModal from "../../components/GenericModal";
import ModalBackground from "../../components/ModalBackground";
import { toast } from "react-toastify";
import CacheBase from "cache-base";
import { element } from "prop-types";

const Login = () => {
  const [input, setInput] = useState("");
  const [hasUser, setHasUser] = useState(false);
  const [hasUserErr, setHasUserErr] = useState(false);
  const [keepConnected, setKeepConnected] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [displayForgotPasswordModal, setDisplayForgotPasswordModal] =
    useState(false);
  const [displayForgotPasswordConfirm, setDisplayForgotPasswordConfirm] =
    useState(false);
  const [modalEmailInput, setModalEmailInput] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingDark, setShowLoadingDark] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const hasTokenLocal = localStorage.getItem("@CRIA_TOKEN");
  const hasTokenSession = sessionStorage.getItem("@CRIA_TOKEN");
  const isMyRoomsLoading = useSelector(
    (state) => state.userRoomsReducer.isLoading
  );
  const myRooms = useSelector((state) => state.userRoomsReducer.rooms);

  const userId = useSelector((state) => state.loginReducer.user.id);
  const contractId = useSelector((state) => state.contractReducer.contractId);
  const isContractLoading = useSelector(
    (state) => state.contractReducer.contract.isLoading
  );

  const brandContrastColor = useSelector(
    (state) => state.contractReducer.contract.config.brandContrastColor
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );
  const alertColor = useSelector(
    (state) => state.contractReducer.contract.config.alertColor
  );
  const logoPath = useSelector(
    (state) => state.contractReducer.contract.config.logoPath
  );

  const history = useHistory();

  const dispatch = useDispatch();

  const refButton = useRef(null);

  const refButton2 = useRef(null);

  const { search } = useLocation();

  const params = React.useMemo(() => new URLSearchParams(search), [search]);

  const app = new CacheBase();

  useEffect(() => {
    if (params.get("token") === "invalid") {
      toast.warning(
        "Sua conta está sendo acessada em outro dispositivo, faça login novamente!"
      );
    }
  }, []);
  useEffect(() => {
    function onKeyup(e) {
      if (e.key === "Enter") {
        if (refButton.current) refButton.current.click();
      }
    }
    window.addEventListener("keyup", onKeyup);
    return () => window.removeEventListener("keyup", onKeyup);
  }, []);

  useEffect(() => {
    function onKeyup2(e) {
      if (e.key === "Enter") {
        if (refButton2.current) refButton2.current.click();
      }
    }
    window.addEventListener("keyup", onKeyup2);
    return () => window.removeEventListener("keyup", onKeyup2);
  }, []);

  const showCrispIcon = () => {
    const crispIcon = document.getElementsByClassName("crisp-client")[0];

    if (crispIcon) {
      crispIcon.style.display = "block";
      crispIcon.style.transition = "5.8s";
      crispIcon.style.margin = "0px!important";
    }
  };

  //Anexar a altura interna da janela e depois remove
  useEffect(() => {
    const handleResize = () => setInnerHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);

    setTimeout(() => {
      showCrispIcon();
    }, 1500);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Mostra o novo ambiente e com o contract que ja foi feito ou vazio de acordo com o id do usuario
  useEffect(() => {
    const hasActiveRooms = myRooms.find(
      (element) => element.status !== "deleted"
    );

    isLoggedIn &&
      (!!hasTokenLocal || !!hasTokenSession) &&
      !isMyRoomsLoading &&
      (myRooms && myRooms.length > 0 && hasActiveRooms
        ? history.push("/")
        : history.push("/novo-ambiente"));

    isLoggedIn &&
      isMyRoomsLoading &&
      !isContractLoading &&
      dispatch(
        userRoomsActions.getUserRooms({ user: userId, contract: contractId })
      );
  }, [isLoggedIn, isMyRoomsLoading, isContractLoading, myRooms]);

  //Navega para a rota
  const handleSubmit = (e, loading) => {
    setShowLoading(true);

    loading = setShowLoading(true);

    e.preventDefault();

    api
      .post("signin/", {
        login: input,
        password: password,
      })
      .then(({ data }) => {
        if (data?.token) {
          //salva dados
          dispatch(loginActions.saveUserData(data));

          if (data.token) {
            if (keepConnected) {
              localStorage.setItem("@CRIA_TOKEN", data.token);
            }
            sessionStorage.setItem("@CRIA_TOKEN", data.token);
          }

          dispatch(contractActions.getContract(data.user.id));

          getOrders(data.user.id);
        }
      })
      .catch((err) => {
        setErrorMessage("login ou senha incorretos.");

        setShowLoading(false);

        loading = setShowLoading(false);
      });
  };

  const getOrders = (id) => {
    api.get(`/order?user=${id}`).then(({ data: dataContract }) => {
      const hasOrder = dataContract.orders.find(
        (element) => element.status !== "deleted"
      );

      hasOrder ? history.push("/") : history.push("/novo-ambiente");
    });
  };

  //Recuperar senha
  const handleRecoverPasswordClick = (loading) => {
    if (modalEmailInput == "") {
      setShowLoadingDark(false);
    } else {
      setShowLoadingDark(true);
      loading = setShowLoadingDark(true);
    }

    api
      .post("recover/", { email: modalEmailInput })
      .then((response) => {
        setDisplayForgotPasswordConfirm(true);
        setShowLoadingDark(false);

        setTimeout(() => {
          setDisplayForgotPasswordConfirm(false);
          setDisplayForgotPasswordModal(false);
        }, 3000);
      })
      .catch((err) => console.log(err));
  };

  useEffect(
    (e) => {
      if (input) {
        api
          .get(`user/check/${input}`)
          .then(({ data }) => {
            if (data.userId) {
              setHasUser(true);
              setHasUserErr(false);
            }
          })
          .catch((err) => {
            setHasUserErr(true);
          });
      }
    },
    [input]
  );

  return (
    <Container
      innerHeight={innerHeight}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      brandColor={brandColor}
      brandContrastColor={brandContrastColor}
      alertColor={alertColor}
    >
      <Header>
        <img src={logoPath} alt="Logotipo"></img>
      </Header>
      <h2>Bem vindo(a) ao CRIA Decor!</h2>
      <h4>
        Para garantir uma experiência personalizada,
        <br />
        faça seu login!
      </h4>
      <h5>{errorMessage}</h5>
      <div className="input-container">
        <DebounceInput
          minLength={2}
          debounceTimeout={400}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={() => {
            setHasUser(false);
            setHasUserErr(false);
          }}
          placeholder="login"
        />
        {hasUser && (
          <span className="verifyUser">
            <i className="fas fa-solid fa-check"></i>
          </span>
        )}

        {hasUserErr && (
          <span className="verifyUserErr">
            <i className="fas fa-solid fa-times"></i>
          </span>
        )}

        {/*<input type="text" placeholder="login" onChange={event => setInput(event.target.value)} />*/}
        <i className="fas fa-sign-in-alt"></i>
      </div>
      <div className="input-container">
        <input
          type="password"
          placeholder="senha"
          onChange={(event) => setPassword(event.target.value)}
        />
        <i className="fas fa-lock"></i>
      </div>
      <div className="keep-login">
        <input
          type="checkbox"
          value={keepConnected}
          onChange={() => setKeepConnected(!keepConnected)}
        />
        Manter conectado
      </div>
      {!showLoading && (
        <input
          ref={refButton2}
          onClick={!displayForgotPasswordModal && handleSubmit}
          className="submit-button"
          type="submit"
          value="ENTRAR"
        />
      )}
      {showLoading && (
        <button className="submit-button">
          <div className="buttonload"></div>
        </button>
      )}

      <small onClick={() => setDisplayForgotPasswordModal(true)}>
        Esqueceu sua senha?
      </small>
      {/* 
            <div className="socialLogin-container">
                <h4>Ou</h4>
                <GoogleLogin
                    clientId="923874282451-6hfkpvs05qd1529d4kpo2pk1ienaja45.apps.googleusercontent.com"
                    render={(renderProps) => (
                        <button onClick={renderProps.onClick} className="social-login-button">
                            <svg height="16" viewBox="0 0 512 512" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z" fill="#4285f4"></path><path d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z" fill="#34a853"></path><path d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z" fill="#fbbc05"></path><path d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z" fill="#ea4335"></path><path d="M20 20h472v472H20V20z"></path></g></svg>
                            Continuar com o Google
                        </button>
                    )}
                    buttonText="Continuar com o Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />
                <FacebookLogin
                    appId="426928071821492"
                    autoLoad={false}
                    callback={responseFacebook}
                    fields="name,email,picture"
                    render={renderProps => (
                        <button onClick={renderProps.onClick} className="social-login-button">
                            <i className="fab fa-facebook"></i>
                            Continuar com o Facebook
                        </button>
                    )}
                />
            </div>
*/}
      <div className="register-container">
        <p>Ainda não possui uma conta?</p>
        <button className="register-button">
          <a onClick={() => history.push("/criar-conta")}>CADASTRAR</a>
        </button>
      </div>

      {/* <div className="old-version">
        <button className="old-version-button">
          <a href="https://appcria.com/login.php">ACESSAR VERSÃO ANTIGA</a>
        </button>
      </div> */}

      <GenericModal
        display={displayForgotPasswordModal}
        onCloseClick={() => setDisplayForgotPasswordModal(false)}
        arrowYPosition={"28vh"}
        arrowXPosition={"6vw"}
        mainContent={
          <>
            {displayForgotPasswordConfirm ? (
              <>
                <h3>Solicitação recebida</h3>
                <p>
                  Recebemos sua solicitação de alteração de senha. Em breve você
                  receberá um e-mail com as instruções para alterá-la!
                </p>
              </>
            ) : (
              <>
                <h3>Esqueceu sua senha?</h3>
                <br />
                <p>
                  Fique tranquilo(a), enviaremos um e-mail para você com um link
                  para alteração!
                </p>
                <label>Digite seu e-mail abaixo</label>
                <input
                  type="text"
                  onChange={(e) => setModalEmailInput(e.currentTarget.value)}
                  value={modalEmailInput}
                  placeholder="e-mail"
                />

                {!showLoadingDark && (
                  <button
                    ref={refButton}
                    onClick={handleRecoverPasswordClick}
                    className="buttons-div"
                  >
                    Recuperar senha
                  </button>
                )}

                {showLoadingDark && (
                  <button className="buttons-div">
                    <div className="buttonload2"></div>
                  </button>
                )}
              </>
            )}
          </>
        }
      ></GenericModal>
      {displayForgotPasswordModal && <ModalBackground></ModalBackground>}
    </Container>
  );
};

export default Login;
