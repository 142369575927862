import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//import { Offline, Online } from "react-detect-offline";
import { useSelector } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import api from "../../services/api";
import Dropdown from "react-dropdown";
// Styles
import {
  OfflineDiv,
  Container,
  Content,
  ContentLabel,
  Label,
  ContentLabel2,
  Sessions,
} from "./styles";

// Components
import SectionTopBar from "../../components/SectionTopBar";
import NavigationBar from "../../components/NavigationBar";
import MyOrders from "./components/MyOrders";
import AllOrders from "./components/AllOrders";
import { setTimeout } from "core-js";

import ReactGA from "react-ga";

const Orders = () => {
  const contract = useSelector((state) => state.contractReducer.contract);
  const isContractLoading = useSelector(
    (state) => state.contractReducer.contract.isLoading
  );

  const User = useSelector((state) => state.loginReducer.user);

  const history = useHistory();

  const brandContrastColor = useSelector(
    (state) => state.contractReducer.contract.config.brandContrastColor
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const [currentSession, setcurrentSession] = useState("myOrders");

  useEffect(() => {
    window.$crisp.push([
      "set",
      "user:nickname",
      `${User.name} - ${contract.name}`,
    ]);
    ReactGA.initialize("UA-126220624-4");
  }, []);

  useEffect(() => {
    if (!isContractLoading) {
      if (User.id) {
        ReactGA.event({
          category: contract.name,
          label: User.name,
          action: "Login",
        });

        api
          .post("/user/" + User.id + "/access", {
            access: {
              accessDate: Date(),
            },
          })
          .catch((err) => console.log(err));
      }
    }
  }, [isContractLoading]);

  return (
    <>
      <SectionTopBar onClickBack={false}></SectionTopBar>
      <Container>
        <Sessions brandcolor={brandColor}>
          <button
            className={currentSession === "myOrders" ? "session-selected" : ""}
            onClick={() => setcurrentSession("myOrders")}
          >
            Meus Atendimentos
          </button>
          <button
            className={currentSession === "allOrders" ? "session-selected" : ""}
            onClick={() => setcurrentSession("allOrders")}
          >
            Inspirações
          </button>
        </Sessions>

        {currentSession === "myOrders" ? (
          <MyOrders history={history}></MyOrders>
        ) : (
          <AllOrders history={history}></AllOrders>
        )}
      </Container>
      <NavigationBar viewIndex={1}></NavigationBar>
    </>
  );
};

export default Orders;
