import styled from 'styled-components'

export const Container = styled.div`
/*display: flex;
flow-flex: row wrap;
justify-content: space-around;
align-items: flex-start;
align-content: flex-start;*/
gap: 12px;
/* width: 100%;
height: 100%; */
background-color: ${props => props.backgroundColor};

h1 {
  margin: 24px;
  text-align: center;
  color: ${props => props.fontColor};
}

label {
  margin-top: 10px;  
  margin-bottom: 4px;
  margin-left: 4px;
  font-family: 'Montserrat', sans-serif;
  color: ${props => props.fontColor};
  font-weight: bold;
}

input {
  padding: 4px 8px;
  border: lightgrey 1px solid;
  border-radius: 15px;
}

.address {
  margin-top: 12px;
}

.address-items {
  margin-left: 24px;
}

.cep-item {
  margin-left: 0px;
}

.city-item {
  margin-left: 0px;  
}

.tiny-field {
  width: 60px;  
}

.small-field {
  width: 120px;  
}

.medium-field {
  width: 180px;  
}

.large-field {
  width: 340px;  
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.formControl {
  margin: spacing(1);
  minWidth: 120;
}

.select {
  marginTop: spacing(2);
}
`

export const ButtonGroup  = styled.div`

`

export const Button  = styled.div`

`