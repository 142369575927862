import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

export default reducers => {
    const persistedReducers = persistReducer(
        {
            key: 'appcria',
            storage,
            whitelist: ['loginReducer']
        },
        reducers
    )

    return persistedReducers
}