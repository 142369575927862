import React from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const GenericModal = (props) => {
    const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    return (
        <Container
            display={props.display ? 'flex' : 'none'}
            arrowYPosition={props.arrowYPosition}
            arrowXPosition={props.arrowXPosition}
            backgroundColor={backgroundColor}
            fontColor={fontColor}
            brandColor={brandColor}
            brandContrastColor={brandContrastColor}
        >
            <i onClick={() => props.onCloseClick()} className="fas fa-times"></i>
            <div className="content">
                {props.mainContent}
            </div>

        </Container>
    )
}

export default GenericModal