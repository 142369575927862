import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    flex-direction: column;
  
  .new-room-form-item {
    width: 70%;
    display: flex;
    flex-direction: column;
  }
  
  .thumb-path-input {
    width: 180px;
  }

  .thumb-path-input img {
    width: 180px;
    border-radius: 15px;    
    margin-bottom: 8px;
  }

  .thumb-path-input button {
    width: 180px;
    padding: 4px 8px;
    border-radius: 15px;
    border: 1px #bebebe solid;
  }

  .room-details {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr;
    margin-bottom: 1rem;
  }
  
  .cameras-container {
    width: 90%;
    border: 1px solid #EAEAEA;
    border-radius: 15px;
    height: auto;
    padding: 24px;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    margin-left: 2rem;
  }
  
  .cameras-container button {
    width: 45%;
    height: 50%;
    align-self: end;
    margin-left: 12px;
  }
  .new-room-form-item-with-no-image{
    justify-content:space-between;
  }
  
  .buttons-camera {
    display: block;
    text-align: end;
  }
  
  .camera-list {
    padding: 24px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction:column;
  }
  
  .camera-list .MuiDataGrid-main {
    min-height:200px  
  }
  
  .camera-list button {
    margin-top:2rem;
    align-self:end;
  }
  
  .cameras-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .end {
    align-self: end;
  }
  
  .btn-novo-ambiente, .btn-vincular-contratos,
  .btn-voltar-camera {
    padding: 7px 11px;
    border-radius: 15px;
    border: none;
    font-size: 12px;
    box-shadow: 0 0.2px 4px rgb(0 0 0 / 10%);
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    color: #fff;
    background-color: #2196F3;
  }

  .btn-voltar-camera {
    margin-top: 24px;
    color: #2196F3;
    background-color: #ffffff;
    border: 1px solid #2196F3;
  }

  .btn-novo-ambiente: hover, .btn-vincular-contratos: hover,
  .btn-voltar-camera: hover {
    opacity: .7;
  }

  .success-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  

`;

export const Butoon = styled.a`
    background-color: #2196f3;
    width: 104px;
    display: flex;
    justify-content: center;
    height: 32px;
    border-radius: 15px;
    align-items: center;
    border-style: hidden;
    color: #ffffff;
    :hover {
        opacity: 0.7;
        cursor: pointer;
    }
`;