import React from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const RoomModelThumb = (props) => {
    const onboarding = useSelector(state => state.loginReducer.onboarding)

    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    return (
        <Container className={onboarding && props.roomModelIndex == 0 ? "active" : "" } onClick={props.onThumbClick} backgroundColor={backgroundColor} fontColor={fontColor}>
            <img data-id={props.typeId} data-playcanvasid={props.playCanvasId} src={props.imgSrc} alt=""/>
            <div className="card-shadow"></div>
            <h4>{props.title}</h4>
        </Container>
    )

}

export default RoomModelThumb