import styled from 'styled-components'

export const Container = styled.ul`
    height: auto;
    width: 100%;
    display: inline-block;
    padding: 8px;
    margin: 2px;

  @media only screen and (min-width: 768px) {
    width: 45%;
    margin: 4px;

    img.thumb {
      width: 120px;
    }

    div.material-data h3 {
      font-size: 1.4em;
    }

    div.material-data h4 {
      font-size: 1em;
    }

  }

  img.thumb {
    border-radius: 20px;
    width: 100px;
    float: left;
    margin-right: 12px;
  }

  div.material-data {
    width: 100%;
    padding: 6px;
  }

  div.material-data h3 {
    font-size: 1.3em;
    color: ${props => props.fontColor};
  }

  div.material-data h4 {
    color: ${props => props.fontColor};
    margin: 3px;  
    font-weight: 500;
    font-size: .9em;
  }

`