import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Styles
import { Container } from './styles'
import FormControl from '@material-ui/core/FormControl'

import { contractContext } from "../../../../Contexts/contractContext";

const Address = (props) => {

  const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  const { currentContract, setCurrentContract } = useContext(contractContext)

  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [municipio, setMunicipio] = useState('');
  const [uf, setUf] = useState('');

  useEffect(() => {
    
    if (currentContract.andress && currentContract.andress.city){
      setCep(currentContract.andress.zipcode)
      setLogradouro(currentContract.andress.andressLine)
      setNumero(currentContract.andress.number)    
      setMunicipio(currentContract.andress.city)
      setUf(currentContract.andress.state)
    }
    else
    {
      setCep('')
      setLogradouro('')
      setNumero('')    
      setMunicipio('')
      setUf('')      
    }

  }, [currentContract])

  useEffect(() => {

    if (currentContract){

      let addressHelper = {}

      if (currentContract.andress){      
        addressHelper = currentContract.andress
      }
      
        addressHelper.zipcode = cep
        addressHelper.andressLine = logradouro
        addressHelper.number = numero
        addressHelper.city = municipio
        addressHelper.state = uf
        
        currentContract.andress = addressHelper

        setCurrentContract(currentContract)
    }

  }, [cep, logradouro, numero, municipio, uf])
  

  return (
      <Container backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor}>
        
        <div className="address">

          <FormControl className="address-items small-field cep-item">
            <label>CEP</label>
            <input type="text" value={cep} onChange={e => setCep(e.target.value)} required />
          </FormControl>

          <FormControl className="address-items large-field">
            <label>Logradouro</label>
            <input type="text" value={logradouro} onChange={e => setLogradouro(e.target.value)} />
          </FormControl>

          <FormControl className="address-items tiny-field">
            <label>Nº</label>
            <input type="text" value={numero} onChange={e => setNumero(e.target.value)} />
          </FormControl>

          <br/>
          <br/>

          <FormControl className="address-items medium-field city-item">
            <label>Município</label>
            <input type="text" value={municipio} onChange={e => setMunicipio(e.target.value)} />          
          </FormControl>
          
          <FormControl className="address-items tiny-field">
            <label>UF</label>
            <input type="text" value={uf} onChange={e => setUf(e.target.value)} />          
          </FormControl>

        </div>
      </Container>
  )
}

export default Address