import { call, put, all, takeEvery } from 'redux-saga/effects'
import * as actions from './actions'
import * as types from '../types'
import api from '../../../services/api'

function* getAllMaterialsReduced ({payload}) {
    try {
        const response = yield call (api.get, '/materialReduced')
        yield put (actions.getAllMaterialsReducedSuccess(response))
    } catch (e) {
        console.log(e)
    }
}


export default all([
    takeEvery(types.GET_ALL_MATERIALS_REDUCED, getAllMaterialsReduced)
])