import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';

// Styles
import { StylesList, Warn } from './styles'

const Styles = (props) => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  const onboarding = useSelector(state => state.loginReducer.onboarding)

  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  useEffect(() => {
    const handleResize = () => setInnerHeight(window.innerHeight)
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <StylesList innerHeight={innerHeight} backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor}>
      {props.templates && props.templates.length > 0 ? props.templates?.map((type, stylesIndex) => (
        <div className="divider" key={type._id}>
          <h2>{type.description}</h2>
          <CarouselProvider className={onboarding === 3 && stylesIndex == 0 ? "divider active" : "divider" }
            naturalSlideWidth={window.innerWidth*0.18}
            naturalSlideHeight={200}
            totalSlides={type.templates.length}
            visibleSlides={1.5}
            isIntrinsicHeight
          >
            <Slider>
              {type.templates.map((template, templateIndex) => (
                <Slide className="slide" key={templateIndex} index={templateIndex} onClick={() => props.handleTemplateThumbClick(template)}>
                  <Image hasMasterSpinner={true} src={template.thumbPath} alt={template.description}></Image>
                </Slide>
              ))}
            </Slider>
            {type.templates.length > 1 &&
              <>
                <ButtonBack className="button-back"><i className="fas fa-arrow-left"></i></ButtonBack>
                <ButtonNext className="button-next"><i className="fas fa-arrow-right"></i></ButtonNext>
              </>
            }
          </CarouselProvider>
        </div>
      ))
      :
      <Warn>
        <h1>Nenhuma inspiração disponível.</h1>
      </Warn>
      }

    </StylesList>
  )
}

export default Styles