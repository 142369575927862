import styled from 'styled-components'

export const Container = styled.div`
/*display: flex;
flow-flex: row wrap;
justify-content: space-around;
align-items: flex-start;
align-content: flex-start;*/
gap: 12px;
/* width: 100%;
height: 100%; */
padding: 24px;
background-color: ${props => props.backgroundColor};

h1 {
  /* margin: 8px 0px;    */
  font-size: 1.6em;
  color: ${props => props.fontColor};
}

label {
  margin-top: 10px;  
  margin-bottom: 4px;
  margin-left: 4px;
  font-family: 'Montserrat', sans-serif;
  color: ${props => props.fontColor};
  font-weight: bold;
}

input {
  padding: 4px 8px;
  border-radius: 15px;  
}

.contrato-selector {
  width: 100%;
  margin: 8px 0px;
}

.Dropdown-arrow {
  right: 0px;
  top: 0px;
  margin: 10px;
}

.dropAndButton {
  display: inline-flex;
}

.dropAndButton .MuiAutocomplete-root{
  margin-top:-20px;
  width:500px !important;
}

.dropAndButton fieldset{
  border-radius: 20px
}

.dropAndButton .MuiAutocomplete-clearIndicator{
  display: none !important
}

.dropAndButton .MuiAutocomplete-root *{
  height:50px !important;
  padding: 0 !important;
}

.dropAndButton .MuiAutocomplete-endAdornment *{
  height: 25px !important;
  margin-top: 10px;
  align-items: flex-end
}

.dropAndButton .MuiAutocomplete-root input{
  font-size: 14px;
  margin-top: 9px;
  margin-left: 10px;
}

.dropContratos .Dropdown-control, .drop-bind-contract .Dropdown-control { 
  border-radius: 25px;
  padding: 4px 52px 4px 10px;
  width: 480px;
  max-height: 26px;
}

.bind-contract-container {
  display:flex;
  flex-direction:column;
  align-items:center;
}

.bind-contract-container .rooms {
  max-height: 250px;
  overflow-y: scroll;
}

.bind-contract-container .rooms label {
  display: flex;
  align-items: center;
}

.bind-contract-container .rooms label input {
  margin-right: 1rem;
}

.drop-bind-contract {
  width: 100%;
  margin-bottom:1rem;
}

.btn-novo-contrato{
  padding: 0px 12px;
  border-radius: 15px;
  border: none;
  font-size: 12px;
  box-shadow: 0 0.2px 4px rgb(0 0 0 / 10%);
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  color: ${props => props.backgroundColor};
  background-color: ${props => props.brandColor};
  margin-left: 16px;
}

.save-btn {
  display: ${props => props.display ? 'block' : 'none'};
  padding: 6px 18px;
  border-radius: 15px;
  border: none;
  font-size: 13px;
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 20%);
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  color: #fff;
  background-color: #2196F3;
  float: right;
  margin-top: 12px;
}

.save-btn:hover{
  opacity: .7;
}

.botoes-sessao-princial {
  display: inline-block;
  color: ${props => props.fontColor};
  border: none;  
  margin: 8px 4px;
}

.botao-sessao-princial {
  padding: 6px;
  border: none;  
  background-color: transparent;
  font-weight: 500;
  margin-right: 24px;
}

.sessao-principal-selecionada {
  border-bottom: ${props => props.brandColor} 2px solid;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.formControl {
  margin: spacing(1);
  minWidth: 120;
}

`