import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../../../services/api'

import { Container } from './styles'

import * as userRoomsActions from './../../../../store/modules/userRooms/actions'

const OrderThumb = (props) => {
    const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)
    const [statusState, setStatusState] = useState(props.status)
    const [showDetails, setshowDetails] = useState(props.showDetails);

    const dispatch = useDispatch()

    const userId = useSelector(state => state.loginReducer.user.id)
    const contractId = useSelector(state => state.contractReducer.contract.contractId)
    
    const handleStatus = (orderId, status) => {

        api
            .put(`order/${orderId}`, {
                "status": status
            })
            .then((data) => {
                setStatusState(status)
                dispatch(userRoomsActions.getUserRooms({ "user": userId, "contract": contractId }))
            })
            .catch((err) => console.log(err))
    }

    const status = (props) => {
        if (statusState == 'open') {
            return (
                <>
                    <div className="open">
                        <a>Em Aberto <i className="drop-status fas fa-chevron-down"></i></a>

                        <div className="menuContainerOpen">
                            <div onClick={() => handleStatus(props.id, 'closed')} className="content">
                                <a>Venda Concluída</a>
                            </div>
                            <div onClick={() => handleStatus(props.id, 'cancelled')} className="content">
                                <a>Venda Perdida</a>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (statusState == 'closed') {
            return (
                <>
                    <div className="closed">
                        <a>Venda Concluída <i className="drop-status fas fa-chevron-down"></i></a>

                        <div className="menuContainerClosed">
                            <div onClick={() => handleStatus(props.id, 'cancelled')} className="content">
                                <a>Venda Perdida</a>
                            </div>
                            <div onClick={() => handleStatus(props.id, 'open')} className="content">
                                <a>Em Aberto</a>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="cancelled">
                        <a>Venda Perdida <i className="drop-status fas fa-chevron-down"></i></a>

                        <div className="menuContainerCancelled">
                            <div onClick={() => handleStatus(props.id, 'closed')} className="content">
                                <a>Venda Concluída</a>
                            </div>
                            <div onClick={() => handleStatus(props.id, 'open')} className="content">
                                <a>Em Aberto</a>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    const handleTrashClick = (orderId) => {
        handleStatus(orderId, 'deleted')
    }

    return (
        <Container fontColor={fontColor} backgroundColor={backgroundColor}>
			<>
				<img src={props.imgSrc ? props.imgSrc : 'https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/template/banheiro-1.jpg'} loading="lazy" alt="" />
				<div onClick={props.onThumbClick} className={showDetails ? "card-shadow" : "card-not-shadow"}></div>
                {showDetails ? <>
                    <h4>{props.title}</h4>
                    <h4 className="name">{props.name}</h4>
                    <h4 className="status">{status(props)}</h4>
                    <button className='btn-trash' onClick={() => handleTrashClick(props.id)}>
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>                    
                    </>
                : <></>}
			</>
        </Container>
    )

}

export default OrderThumb