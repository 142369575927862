import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from "pure-react-carousel";

// Styles
import { Container } from "./styles";

const TypesTable = ({ data, action, ...rest }) => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const onboarding = useSelector((state) => state.loginReducer.onboarding);

  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  useEffect(() => {
    const handleResize = () => setInnerHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container
      innerHeight={innerHeight}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
    >
      {data &&
        data.length &&
        data.map((category, categoryIndex) => {
          return (
            <div
              className={
                onboarding === 5 && categoryIndex == 0
                  ? "divider active"
                  : "divider"
              }
              key={categoryIndex}
            >
              <h2>{category.description}</h2>
              <CarouselProvider
                className="divider"
                naturalSlideWidth={window.innerWidth * 0.18}
                naturalSlideHeight={100}
                totalSlides={category.reproduction.length}
                visibleSlides={3.7}
                step={3}
                isIntrinsicHeight
              >
                <Slider>
                  {category.reproduction.map((item, index) => (
                    <Slide
                      key={index}
                      index={index}
                      onClick={() =>
                        action({
                          type: item,
                          category: category.description,
                        })
                      }
                    >
                      <Image
                        hasMasterSpinner={true}
                        src={item.thumbPath}
                        alt={item.description}
                      ></Image>
                      <div className="card-shadow"></div>
                      <h2 className="list__item__title">{item.description}</h2>
                    </Slide>
                  ))}
                </Slider>
                {category.reproduction.length > 3 && (
                  <>
                    <ButtonBack className="button-back">
                      <i className="fas fa-arrow-left"></i>
                    </ButtonBack>
                    <ButtonNext className="button-next">
                      <i className="fas fa-arrow-right"></i>
                    </ButtonNext>
                  </>
                )}
              </CarouselProvider>
            </div>
          );
        })}
      <div className="space"></div>
    </Container>
  );
};

export default TypesTable;
