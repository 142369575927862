import styled, { keyframes } from "styled-components";

const success = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;

export const Container = styled.div`
  .bk {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.5;
    z-index: 99999999999;
  }

  .content {
    position: fixed;
    height: fit-content;
    min-height: 445px;
    width: 800px;
    background: white;
    z-index: 9999999999999999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 4px;
  }

  .loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  .container {
    padding: 16px;
  }

  .inputClass {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  .inputClass input {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    font-size: 16px;
  }

  .inputItem {
    width: 100%;
  }

  .container > p {
    font-size: 24px;
    margin-bottom: 36px;
  }

  .inputItem select {
    width: 100%;
    height: 40px;
    border: 1px #bebebe solid;
    font-size: 16px;
    border-radius: 4px;

    option {
      height: 20px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    button {
      :first-child {
        height: 36px;
        width: 150px;
        border: 0;
        background: #ff5f5f;
        color: white;
        border-radius: 4px;
      }

      :last-child {
        height: 36px;
        width: 150px;
        border: 0;
        background: #2196f3;
        color: white;
        border-radius: 4px;
      }
    }
  }

  .delete {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 16px;
    }

    > div {
      margin-top: 24px;
      display: flex;
      gap: 16px;
      button {
        :last-child {
          height: 36px;
          width: 150px;
          border: 0;
          background: #ff5f5f;
          color: white;
          border-radius: 4px;
        }

        :first-child {
          height: 36px;
          width: 150px;
          border: 1px solid gray;
          background: white;
          color: gray;
          border-radius: 4px;
        }
      }
    }
  }

  .success {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 24px;
    }

    span {
      margin-top: 24px;
      font-size: 28px;
      background-color: green;
      color: white;
      padding: 16px;
      border-radius: 9999px;
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: ${success} 0.8s forwards;
    }
  }

  .close {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 16px;
    cursor: pointer;
  }
`;
