import * as types from '../types'

export function getOrders(payload) {
    return {
        type: types.GET_ORDERS,
        payload,
    }
}

export function getOrdersSuccess(payload) {
    return {
        type: types.GET_ORDERS_SUCCESS,
        payload,
    }
}