import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "./styles";
import { contractContext } from "../../../../../Contexts/contractContext";

const RoomThumb = (props) => {
    const user = useSelector((state) => state.loginReducer.user);

    const { currentContract, setCurrentContract } = useContext(contractContext);

    const { checked, roomId, category, checkAll, setCheckALl, data } = props;

    const setSelectAll = (roomInContract) => {
        const rooms = data.find(
            (categoryInData) => categoryInData.description === category
        ).rooms;

        const newCheckAll = checkAll.map((check) => {
            if (check.category === category)
                return {
                    category: check.category,
                    select: rooms.every((roomOfCategory) =>
                        roomInContract.includes(roomOfCategory._id)
                    ),
                };
            else return check;
        });

        setCheckALl(newCheckAll);
    };
    const handleClick = (event) => {
        const roomInContract = !checked
            ? [...currentContract.rooms, roomId]
            : currentContract.rooms.filter((room) => room !== roomId);
        setCurrentContract((prevContract) => {
            return { ...prevContract, rooms: roomInContract };
        });
        setSelectAll(roomInContract);
    };

    return (
        <Container>
            <label className="checkbox">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleClick}
                ></input>
                <span className="checkmark"></span>
            </label>
            {user.type === "admin" ? (
                <div className="editar" onClick={(e) => props.editClick()}>
                    Editar
                </div>
            ) : (
                ""
            )}
            <h4>{props.title}</h4>
            <img data-id={props.roomId} src={props.imgSrc} alt="" />
            <div className="card-shadow"></div>
        </Container>
    );
};

export default RoomThumb;
