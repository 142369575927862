import styled from "styled-components";

export const Container = styled.div`
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 8px;
  overflow-x: hidden;

  @media only screen and (min-width: 1024px) and (max-height: 1750px) {
    width: 380px;
    height: 100%;
    overflow-y: hidden;

    > div {
      width: 364px !important;
    }
  }

  nav.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 38px;
    border-top: 1px solid rgba(0, 0, 0, 0.03);
    width: 100vw;
  }

  nav.navbar h1 {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.fontColor};
    margin: auto auto auto 12px;
  }

  nav.navbar i.fa-arrow-left {
    color: ${(props) => props.fontColor};
    font-size: 14px;
    margin-left: 16px;
    cursor: pointer;
  }

  nav.navbar i.fa-arrow-left:hover {
    opacity: 0.8;
  }
`;
export const ContainerLoading = styled.div`
  margin-top: 50%;
  margin-left: 38%;
  border: 5px solid ${(props) => props.backgroundColor};
  border-radius: 50%;
  border-top: 5px solid ${(props) => props.brandColor};
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @media only screen and (min-width: 1024px) {
    margin-top: 80%;
    margin-left: 34%;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SelectFaceAlert = styled.div`
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100vw;
  height: 100%;
  background: ${(props) => props.backgroundColor};
  z-index: 10;
  position: absolute;
  opacity: 0.96;
  color: ${(props) => props.fontColor};
  font-size: 16px;
  text-align: center;
  padding: 16px;

  div.icon-div {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  div.icon-div img {
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
  }

  @media only screen and (min-width: 1024px) {
    width: 380px;
    position: relative;
    height: 100%;
    overflow-y: hidden;
  }
`;

export const LastAppliedList = styled.div`
  width: 94vw;
  margin: 12px 3vw auto 3vw;

  @media only screen and (min-width: 1024px) {
    width: 372px !important;
    margin: 12px 0 auto 8px !important;

    img {
      margin: 0 4px 6px 4px !important;
    }

    div {
      width: 364px !important;
    }
  }

  @media only screen and (min-height: 1750px) and (max-width: 1080px) {
    width: 100%!important;
  }  

  h2 {
    color: ${(props) => props.fontColor};
    font-size: 14px;
    margin: 0 0 10px 4px;
    font-weight: 500;
    text-align: start;
    width: 100%;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin: 0 0.2rem 0.2rem 0.2rem;
    cursor: pointer;
  }

  img:hover {
    opacity: 0.8;
  }

  div {
    width: 94vw;
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;
