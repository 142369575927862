import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  height: 48px;
  width: calc(100vw - 16px);
  margin: 0 8px 0 8px;
  align-items: center;
  justify-content: start;
  overflow-x: auto;
  overflow-y: hidden;


  @media only screen and (min-height: 1750px) and (max-width: 1080px) {
    width: 384px!important;
  }
  
  @media only screen and (min-width: 1024px) {
    width: 284px;
    /*margin-bottom: 8px;*/
  }
  
  button {
    border: none;
    height: 20px;
    width: fit-content;
    border-radius: 12px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #222;
    background: #fff;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 4px;
    margin-right: 4px;
    box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);
    white-space: nowrap;
  }

  button:hover {
    opacity: .8;
  }

  button i {
    font-size: 11px;
    opacity: .8;
    margin-left: 3px;
  }

  button.selected {
    border: 1px solid ${props => props.brandColor};
    color: ${props => props.brandColor};
  }
`
