import React, { useState, useEffect, useRef } from "react";
import api from "../../services/api";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import { DebounceInput } from "react-debounce-input";
import * as loginActions from "./../../store/modules/login/actions";
import * as userRoomsActions from "./../../store/modules/userRooms/actions";
import * as contractActions from "./../../store/modules/contract/actions";

// Styles
import { Container, Header, TermsModal } from "./styles";

const CreateAccount = () => {
  const [name, setName] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState(55);
  const [company, setCompany] = useState("");
  const [login, setLogin] = useState("");
  const [whereKnows, setWhereKnows] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [displayTermsModal, setDisplayTermsModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [profession, setProfession] = useState("");
  const serviceTerms = <div>Termos de serviço.</div>;
  const privacyPolicy = <div>Política de privacidade.</div>;

  const userId = useSelector((state) => state.loginReducer.user.id);
  const contractId = "61031cdbf770c825dca7647b";
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const isMyRoomsLoading = useSelector(
    (state) => state.userRoomsReducer.isLoading
  );
  const myRooms = useSelector((state) => state.userRoomsReducer.rooms);

  const brandContrastColor = useSelector(
    (state) => state.contractReducer.contract.config.brandContrastColor
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );
  const alertColor = useSelector(
    (state) => state.contractReducer.contract.config.alertColor
  );
  const logoPath = useSelector(
    (state) => state.contractReducer.contract.config.logoPath
  );

  const history = useHistory();

  const dispatch = useDispatch();

  const refButton = useRef(null);

  const [session, setSession] = useState("one");

  const [validCredentials, setValidCredentials] = useState(false);

  useEffect(() => {
    function onKeyup(e) {
      if (e.key === "Enter") {
        refButton.current.click();
      }
    }
    window.addEventListener("keyup", onKeyup);
    return () => window.removeEventListener("keyup", onKeyup);
  }, []);

  useEffect(() => {
    const handleResize = () => setInnerHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    isLoggedIn &&
      !isMyRoomsLoading &&
      (myRooms && myRooms.length > 0
        ? history.push("/")
        : history.push("/novo-ambiente"));
    isLoggedIn &&
      isMyRoomsLoading &&
      dispatch(
        userRoomsActions.getUserRooms({ user: userId, contract: contractId })
      );
  }, [isLoggedIn, isMyRoomsLoading]);

  const addUserToContract = (userId) => {
    return new Promise((resolve, reject) => {
      api
        .post(`contract/${contractId}/user`, {
          user: userId,
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(false);
        });
    });
  };

  const sendWhatsappMsg = () => {
    const whatsappMessage = `Opa, uma nova pessoa se cadastrou no Freemium. Segue seus dados para contato:
            
        Nome: ${name}
        Fone: ${phone}
        E-mail: ${emailInput}
        Loja: ${company}
        Onde conheceu: ${whereKnows}
        Atuação: ${profession}

        Clique em -> wa.me/${phone} e chame ela no whatsapp.`;

    api
      .post("global/sendwhatsapp", {
        phone: 5544997121370,
        message: whatsappMessage,
      })
      .then(({ data }) => {})
      .catch((err) => console.log(err));
  };

  const userRegister = (loading) => {
    setShowLoading(true);
    loading = setShowLoading(true);

    return new Promise((resolve, reject) => {
      api
        .post("signup/", {
          email: emailInput,
          password: password,
          name: name,
          phone: phone,
          login: login,
          company: company,
          whereKnows: whereKnows,
          profession: profession,
          type: "lead",
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(false);
        });
    });
  };

  const checkCredentials = (state, value) => {
    if (state === "email") {
      validateEmail(value);
    } else if (state === "login") {
      validateLogin(value);
    }
  };

  const validateEmail = (value) => {
    setEmailInput(value);

    const mailformat = /^\w*(\.\w*)?@\w*\.[a-z]+(\.[a-z]+)?$/;
    const isValid = value.match(mailformat);

    if (!isValid) {
      setErrorMessage("E-mail inválido. Tente novamente.");
      setValidCredentials(false);
      return;
    }

    if (value == login) {
      setErrorMessage("E-mail e login não podem ser iguais");
      setValidCredentials(false);
      return;
    }

    api
      .get("user/email/" + value)
      .then(() => {
        setErrorMessage("E-mail já foi cadastrado. Por favor, escolha outro.");
        setValidCredentials(false);
      })
      .catch((err) => {
        setErrorMessage("");
        setValidCredentials(true);
      });
  };

  const validateLogin = (value) => {
    if (value != emailInput) {
      api
        .get("user/login/" + value)
        .then(() => {
          setErrorMessage("Login já cadastrado. Por favor, escolha outro.");
          setValidCredentials(false);
        })
        .catch((err) => {
          setErrorMessage("");
          setValidCredentials(true);
          setLogin(value);
        });
    } else {
      setErrorMessage("E-mail e login não podem ser iguais.");
      setValidCredentials(false);
    }
  };

  const validateFormOne = () => {
    if (!validatePassword(password)) {
      setErrorMessage("A senha deve conter 4 ou mais caracteres.");
      return false;
    }

    if (!phone || phone.length < 13) {
      setErrorMessage(
        "O campo de telefone está incorreto, exemplo: 5511999999999."
      );
      return false;
    }

    if (!name) {
      setErrorMessage("O campo nome não pode estar vazio.");
      return false;
    }

    checkCredentials("email", emailInput);

    if (!validCredentials) {
      return false;
    }

    checkCredentials("login", login);

    if (!validCredentials) {
      return false;
    }

    if (!emailInput) {
      setErrorMessage("O campo e-mail não pode estar vazio.");
      return false;
    }

    return true;
  };

  const validateFormTwo = () => {
    if (!whereKnows) {
      setErrorMessage("Onde nos conheceu não pode estar vazio.");
      return false;
    }

    if (!profession) {
      setErrorMessage("Área de atuação não deve estar vazia.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateFormTwo() && validCredentials) {
      const registerResp = await userRegister();

      if (registerResp.data.success) {
        const addUserResp = await addUserToContract(registerResp.data.id);

        if (addUserResp.data.success) {
          sendWhatsappMsg();
          //   dispatch(
          //     loginActions.loginSubmit({
          //       login: emailInput,
          //       password: password,
          //     })
          //   );

          api
            .post("signin/", {
              login: emailInput,
              password: password,
            })
            .then(({ data }) => {
              if (data?.token) {
                //salva dados
                dispatch(loginActions.saveUserData(data));

                if (data.token) {
                  localStorage.setItem("@CRIA_TOKEN", data.token);
                  sessionStorage.setItem("@CRIA_TOKEN", data.token);
                }

                dispatch(contractActions.getContract(data.user.id));

                history.push("/Tutorial");
              }
            });
        }
      }
    }
  };

  function validatePassword(inputText) {
    const isValid = inputText.length > 3;
    return isValid;
  }

  const responseGoogle = (response) => {
    isUserRegistered(
      response.profileObj.email,
      response.profileObj.name,
      response.googleId,
      false
    );
  };

  const responseFacebook = (response) => {
    isUserRegistered(response.email, response.name, false, response.userID);
  };

  const isUserRegistered = (email, name, googleId, facebookId) => {
    api
      .get("user/email/" + email)
      .then((response) =>
        updateUser(response.userId, email, googleId, facebookId)
      )
      .catch(() => createUser(email, name, googleId, facebookId));
  };

  const updateUser = (userId, email, googleId, facebookId) => {
    api
      .put(
        "signup/" + userId,
        facebookId ? { facebookId: facebookId } : { googleId: googleId }
      )
      .then(() => {
        facebookId
          ? dispatch(
              loginActions.socialLoginSubmit({
                email: email,
                facebookId: facebookId,
              })
            )
          : dispatch(
              loginActions.socialLoginSubmit({
                email: email,
                googleId: googleId,
              })
            );
      })
      .catch((err) => console.log(err));
  };

  const createUser = (email, name, googleId, facebookId) => {
    api
      .post(
        "signup/",
        facebookId
          ? { email: email, name: name, facebookId: facebookId }
          : { email: email, name: name, googleId: googleId }
      )
      .then(() => {
        dispatch(
          loginActions.socialLoginSubmit(
            facebookId
              ? { email: email, facebookId: facebookId }
              : { email: email, googleId: googleId }
          )
        );
      })
      .catch((err) => console.log(err));
  };

  const showServiceTerms = () => {
    setModalTitle("Termos de Serviço");
    setModalContent(serviceTerms);
    setDisplayTermsModal(true);
  };

  const showPrivacyPolicy = () => {
    setModalTitle("Política de Privacidade");
    setModalContent(privacyPolicy);
    setDisplayTermsModal(true);
  };

  const handleChangeSession = () => {
    validateFormOne() && setSession("two");
  };

  const sessionOne = () => {
    return (
      <>
        <div className="input-container">
          <DebounceInput
            minLength={2}
            debounceTimeout={300}
            onChange={(e) => setName(e.target.value)}
            placeholder="nome"
          />
          <i className="fas fa-user"></i>
        </div>
        <div className="input-container">
          <DebounceInput
            minLength={2}
            debounceTimeout={1000}
            onChange={(e) => checkCredentials("email", e.target.value)}
            placeholder="e-mail"
          />
          <i className="fas fa-envelope"></i>
        </div>
        <div className="input-container">
          {/* <InputMask mask="9999999999999" type="tel" placeholder="fone" onChange={event => setPhone(event.target.value)} /> */}
          <input
            type="text"
            placeholder="fone"
            onChange={(event) => setPhone(event.target.value)}
            value={phone}
          />
          <i className="fas fa-phone"></i>
        </div>

        <div className="input-container">
          <DebounceInput
            minLength={2}
            debounceTimeout={300}
            onChange={(e) => checkCredentials("login", e.target.value)}
            placeholder="login"
          />
          <i className="fas fa-sign-in-alt"></i>
        </div>

        <div className="input-container">
          <input
            type="password"
            placeholder="senha"
            onChange={(event) => setPassword(event.target.value)}
            required
          />
          <i className="fas fa-lock"></i>
        </div>

        <input
          ref={refButton}
          onClick={() => handleChangeSession()}
          className="next-button"
          value="CONTINUAR"
        />
      </>
    );
  };

  const sessionTwo = () => {
    return (
      <>
        <div
          className="radio-container"
          onChange={(event) => setWhereKnows(event.target.value)}
          required
        >
          <div className="form-check radio-title">
            <label>Onde nos conheceu?</label>
          </div>
          <div className="whereKnows">
            <div className="form-check">
              <input type="radio" value="redes sociais" name="social" /> Redes
              Sociais
            </div>
            <div className="form-check">
              <input type="radio" value="google" name="google" /> Google
            </div>
            <div className="form-check">
              <input type="radio" value="indicação" name="recommendation" />{" "}
              Indicação
            </div>
            <div className="form-check">
              <input type="radio" value="outra loja" name="anotherCompany" />{" "}
              Outra loja
            </div>
            <div className="form-check">
              <input type="radio" value="outro" name="other" /> Outro
            </div>
          </div>
        </div>

        <div
          className="radio-container"
          onChange={(event) => setProfession(event.target.value)}
          required
        >
          <div className="form-check radio-title">
            <label>Qual sua área de atuação?</label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              value="revestimentos/lojista"
              name="profession"
            />{" "}
            Revestimentos - Lojista
          </div>
          <div className="form-check">
            <input type="radio" value="moveis/marcenaria" name="profession" />{" "}
            Moveis - Marcenaria
          </div>
          <div className="form-check">
            <input type="radio" value="arquiteto/design" name="profession" />{" "}
            Arquiteto - Design de Interior
          </div>
          <div className="form-check">
            <input type="radio" value="pedreiro/azulejista" name="profession" />{" "}
            Profissional - Pedreiro/Azulejista
          </div>
          <div className="form-check">
            <input type="radio" value="outro" name="profession" /> Outro
          </div>
        </div>

        <div className="input-container">
          <DebounceInput
            minLength={2}
            debounceTimeout={300}
            onChange={(e) => setCompany(e.target.value)}
            placeholder="loja"
          />
          <i className="fas fa-store"></i>
        </div>

        {!showLoading && (
          <div className="form-check">
            <input
              ref={refButton}
              onClick={!displayTermsModal && handleSubmit}
              className="submit-button"
              type="submit"
              value="CRIAR CONTA"
            />
          </div>
        )}

        {showLoading && (
          <button className="submit-button">
            <div className="buttonload"></div>
          </button>
        )}

        <input
          ref={refButton}
          onClick={() => setSession("one")}
          className="back-button"
          value="VOLTAR"
        />
      </>
    );
  };

  return (
    <Container
      innerHeight={innerHeight}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      brandColor={brandColor}
      brandContrastColor={brandContrastColor}
      alertColor={alertColor}
    >
      <Header>
        <img src={logoPath} alt="Logotipo"></img>
      </Header>
      <h2>Bem vindo(a) ao CRIA Decor!</h2>
      <h3>Explore o designer que há em você!</h3>
      <h4>
        Crie ambientes 3D incríveis com os melhores acabamentos do mercado.
      </h4>

      {session === "one" ? sessionOne() : sessionTwo()}

      <h5>{errorMessage}</h5>
      <div className="socialLogin-container">
        <h4>Ou</h4>
        <GoogleLogin
          clientId="923874282451-6hfkpvs05qd1529d4kpo2pk1ienaja45.apps.googleusercontent.com"
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              className="social-login-button"
            >
              <svg
                height="16"
                viewBox="0 0 512 512"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z"
                    fill="#4285f4"
                  ></path>
                  <path
                    d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z"
                    fill="#34a853"
                  ></path>
                  <path
                    d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z"
                    fill="#fbbc05"
                  ></path>
                  <path
                    d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z"
                    fill="#ea4335"
                  ></path>
                  <path d="M20 20h472v472H20V20z"></path>
                </g>
              </svg>
              Continuar com o Google
            </button>
          )}
          buttonText="Continuar com o Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
        <FacebookLogin
          appId="160857772406897"
          autoLoad={false}
          callback={responseFacebook}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              className="social-login-button"
            >
              <i className="fab fa-facebook"></i>
              Continuar com o Facebook
            </button>
          )}
        />
      </div>
      <h6>
        Ao continuar, você concorda com os{" "}
        <a onClick={(event) => showServiceTerms()}>Termos de Serviço</a>
        <br />e com a{" "}
        <a onClick={(event) => showPrivacyPolicy()}>
          Política de Privacidade
        </a>{" "}
        do CRIA Decor.
      </h6>
      <a onClick={() => history.push("/login")}>
        Já possui uma conta? Entre aqui!
      </a>
      {displayTermsModal && (
        <TermsModal
          backgroundColor={backgroundColor}
          fontColor={fontColor}
          brandColor={brandColor}
        >
          <i
            onClick={() => setDisplayTermsModal(false)}
            className="fas fa-times"
          ></i>
          <h3 className="modal-title">{modalTitle}</h3>
          <p className="modal-subtitle">{modalContent}</p>
        </TermsModal>
      )}
    </Container>
  );
};

export default CreateAccount;
