import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    height: ${props => props.innerHeight}px;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background: url(https://images.unsplash.com/photo-1519710164239-da123dc03ef4?ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80), rgba(245, 245, 245, 0.7);
    background-blend-mode: overlay;
    background-size: 100% auto;
    font-family: 'Montserrat', sans-serif;
    min-height: 560px;
    overflow-y: auto;

    @media only screen and (min-width: 1024px) {
        background: url(https://images.unsplash.com/photo-1484154218962-a197022b5858?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1506&q=80), rgba(245, 245, 245, 0.9);
    }

    h1 {
        font-size: 24px;
        margin-top: 32px;
        border-bottom: 3px solid ${props => props.brandColor};
    }

    h2 {
        font-size: 24px;
        text-align: center;
        margin: 24px auto;
    }

    h3 {
        font-size: 18px;
        text-align: center;
        margin: 24px auto;
        color: ${props => props.fontColor};
        font-weight: 600;
    }
    
    .div-video {
        /* box-shadow: black 4px 4px 15px 1px; */
        box-shadow: black 0px 0px 8px 1px;
    }

    a.submit-button {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        margin: 32px auto;
        width: 222px;
        height: 32px;
        border-radius: 16px;
        border: none;
        font-size: 16px;
        box-shadow: 1px 1px 4px rgb(0 0 0 / 25%);
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-family: 'Montserrat',sans-serif;
        font-weight: 500;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        background: ${props => props.brandColor};
        color: ${props => props.brandContrastColor};
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    input.submit-button:hover,
    a: hover,
    h6 a:hover {
        opacity: .8;
        cursor: pointer;
    }

    @media only screen and (max-width: 1367px) {
        h1 {
            font-size: 20px;
            margin-top: 42px;
            border-bottom: 3px solid ${props => props.brandColor};
        }

        a.submit-button {
            margin: 0px;
            margin-top: 24px;
        }

        .div-video {
            width: 680px;
            height: 380px;            
        }

        h3 {
            margin: 20px auto;
        }

    }

`

export const Header = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex: 1;
  height: 38px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.7);
  z-index: 1;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);

  img {
    height: 24px;
  }
`