import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background: url(https://images.unsplash.com/photo-1519710164239-da123dc03ef4?ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80), rgba(245, 245, 245, 0.7);
    background-blend-mode: overlay;
    background-size: 100% auto;
    font-family: 'Montserrat', sans-serif;

    h2 {
        font-size: 14px;
        margin-top: 32px;
        padding-bottom: 10px;
        border-bottom: 3px solid ${props => props.brandColor};
        text-align: center;
        font-weight: 500;
    }

    h4 {
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        font-weight: 500;
    }

    h5 {
        font-size: 11px;
        margin-bottom: 6px;
        text-align: center;
        color: ${props => props.alertColor};
        font-weight: 600;
    }

    div.input-container {
        display: flex;
        position: relative;
        align-items: center;
        width: 222px;
        height: 32px;
        margin: 0 auto 6px auto;
        background: ${props => props.backgroundColor};
        border-radius: 16px;
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        border: 1px solid rgba(20, 20, 20, 0.2);
        padding: 0 16px 0 16px;
    }

    div.input-container:focus-within,
    div.input-container:hover {
        border: 1px solid ${props => props.brandColor};
    }

    div.input-container input {
        background: ${props => props.backgroundColor};
        border: none;
        margin: auto auto auto 0;
        height: 100%;
        width: 100%;
        color: ${props => props.fontColor};
    }

    div.input-container i {
        opacity: 0.7;
    }

    button {
        display: flex;
        position: relative;
        width: 222px;
        height: 32px;
        border-radius: 16px;
        border: none;
        font-size: 12px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        font-family: 'Montserrat',sans-serif;
        font-weight: 500;
        margin-top: 16px;
    }

    button:hover,
    a: hover,
    small:hover {
        opacity: .8;
        cursor: pointer;
    }

    button.submit-button {
        background: ${props => props.brandColor};
        color: ${props => props.backgroundColor};
        font-weight: 400;
    }

    button.social-login-button {
        background: ${props => props.backgroundColor};
        color: ${props => props.fontColor};
    }

    button.social-login-button i {
        font-size: 16px;
        color: #3B5998;
        margin-right: 6px;
    }

    button.social-login-button svg {
        margin-right: 4px;
    }

    h6 {
        font-size: 8px;
        text-align: center;
        font-weight: 500;
    }

    small {
        font-size: 10px;
        color: ${props => props.fontColor};
        text-decoration: underline;
        font-weight: 900;
    }

    a {
        font-size: 12px;
        color: ${props => props.brandColor};
        font-weight: 900;
        margin-top: 24px;
        text-decoration: underline;
    }
`

export const Header = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex: 1;
  height: 38px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.7);
  z-index: 1;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);

  img {
    height: 24px;
  }
`