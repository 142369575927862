import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import api from "../../../../services/api";

// Styles
import { Container, Button, ButtonGroup } from './styles'
import InputMask from 'react-input-mask';
import FormControl from '@material-ui/core/FormControl'
import Switch from "react-switch";

import 'react-dropdown/style.css'

import { contractContext } from "../../../../Contexts/contractContext";

import { DebounceInput } from "react-debounce-input";

import Loader from "../../../../components/Loader";

const MainData = (props) => {

  const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
  const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  const { currentContract, setCurrentContract } = useContext(contractContext)

  const [nomeRazao, setNomeRazao] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [fone, setFone] = useState('');
  const [responsavel, setResponsavel] = useState('');
  const [emailFinanceiro, setEmailFinanceiro] = useState('');
  const [associacao, setAssociacao] = useState('');
  const [status, setStatus] = useState('');
  const [valorMensalidade, setValorMensalidade] = useState('');
  const [diaFaturamento, setDiaFaturamento] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    
    if (currentContract && currentContract.name){      
      setNomeRazao(currentContract.name)
      setCnpj(currentContract.cpfcnpj)
      setFone(currentContract.phone)
      setResponsavel(currentContract.responsible)
      setEmailFinanceiro(currentContract.email)
      setAssociacao(currentContract.association)
      setStatus(currentContract.status)

      if(currentContract.plan) {
        setDiaFaturamento(currentContract.plan.payDay)
        setValorMensalidade(currentContract.plan.specificValue)
      }
    }
    else
    {
      setNomeRazao('')
      setCnpj('')
      setFone('')
      setResponsavel('')
      setEmailFinanceiro('')
      setAssociacao('')
      setStatus('active')
      setDiaFaturamento('')
      setValorMensalidade('')
    }

  }, [currentContract])

  
  useEffect(() => {

    if (currentContract){      
      const contractHelper = currentContract

      contractHelper.name = nomeRazao
      contractHelper.cpfcnpj = cnpj
      contractHelper.phone = fone
      contractHelper.responsible = responsavel
      contractHelper.email = emailFinanceiro
      contractHelper.association = associacao
      contractHelper.status = status

      if (contractHelper.plan){
        contractHelper.plan.payDay = diaFaturamento
        contractHelper.plan.specificValue = valorMensalidade
      } else {
        const plan = {
          payDay : diaFaturamento,
          specificValue : valorMensalidade
        }
        contractHelper.plan = plan
      }

      setCurrentContract(contractHelper)
    }
  }, [nomeRazao, cnpj, fone, responsavel, emailFinanceiro, associacao, status, diaFaturamento, valorMensalidade])
  
  const handleStatusChange = (value) => {
    setStatus(value ? "active" : "inactive")
  }

  const consultaCNPJ = async(cnpj) => {
    setIsLoading(true)
    
    try{

      const respCnpj = await api.get(`global/consultaCNPJ/${cnpj}`)

      if (respCnpj.data.data) {

        const infoContract = respCnpj.data.data

        if (currentContract){
            currentContract.andress = {
            city : infoContract.municipio,
            zipcode : infoContract.cep,
            andressLine : infoContract.logradouro,
            number : infoContract.numero == 'S/N' ? '' : infoContract.numero,
            state : infoContract.uf  
          }
        }
          
        setNomeRazao(infoContract.nome)
        setFone(infoContract.telefone)

      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleCnpjChange = (cnpj) => {

    setCnpj(cnpj)

    if (cnpj && cnpj.length >= 14){      
      consultaCNPJ(cnpj)
    }

  }

  return (
      <Container backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor}>

        <div className="dados-cadastrais">

          {isLoading ? 
              <Loader
              borderRadius={"15px"}
              />
            : <>

              <FormControl className="dados-cadastrais-items cnpj-item">
                <label>CNPJ <small>(apenas números)</small></label>
                {/* <input type="text" value={cnpj} onChange={e => setCnpj(e.target.value)} /> */}
                <DebounceInput minLength={2} debounceTimeout={500} onChange={(e) => handleCnpjChange(e.target.value)} value={cnpj} />
              </FormControl>

              <FormControl className="dados-cadastrais-items nome-item">
                <label>Nome/Razão Social</label>
                <input type="text" value={nomeRazao} onChange={e => setNomeRazao(e.target.value)} required />
              </FormControl>

              <FormControl className="dados-cadastrais-items">
                <label>Fone</label>
                <InputMask mask="5599999999999" type="tel" value={fone} placeholder="(xx) 00000-0000" onChange={e => setFone(e.target.value)} />
              </FormControl>

              <FormControl className="dados-cadastrais-items">
                <label>Ativo</label>
                <Switch onChange={handleStatusChange} checked={status === 'active'} />
              </FormControl>

              <br/>
              <br/>

              <FormControl className="dados-cadastrais-items responsavel-item">
                <label>Nome Responsável</label>
                <input type="text" value={responsavel} onChange={e => setResponsavel(e.target.value)} />
              </FormControl>

              <FormControl className="dados-cadastrais-items email-item">
                <label>E-mail Financeiro</label>
                <input type="text" value={emailFinanceiro} onChange={e => setEmailFinanceiro(e.target.value)} />          
              </FormControl>

              <FormControl className="dados-cadastrais-items associacao-item">
                <label>Associação</label>
                <input type="text" value={associacao} onChange={e => setAssociacao(e.target.value)} />          
              </FormControl>

              <br/>
              <br/>

              <FormControl className="dados-cadastrais-items valor-mensalidade-item">
                <label>Valor Mensalidade</label>
                <input type="number" pattern="\d*" value={valorMensalidade} onChange={e => setValorMensalidade(e.target.value)} />          
              </FormControl>

              <FormControl className="dados-cadastrais-items dia-faturamento-item">
                <label>Dia Faturamento</label>
                <input type="number" value={diaFaturamento} onChange={e => setDiaFaturamento(e.target.value)} />          
              </FormControl>
            </>}
        </div>
      </Container>
  )
}

export default MainData