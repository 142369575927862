import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as ordersActions from '../../../../store/modules/orders/actions'

// Styles
import { Container } from './styles'

// Components
import AllThumb from './components/AllThumb'

const AllList = (props) => {
    const [innerHeight, setInnerHeight] = useState(window.innerHeight)

    const isMyOrdersLoading = useSelector(state => state.ordersReducer.isLoading)
    const Orders = useSelector(state => state.ordersReducer.orders)

    const isContractLoading = useSelector(state => state.contractReducer.contract.isLoading)

    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    const dispatch = useDispatch()

    useEffect(() => {
        (isMyOrdersLoading && !isContractLoading) && dispatch(ordersActions.getOrders(null))
    }, [isContractLoading, isMyOrdersLoading, Orders]);

    useEffect(() => {
        const handleResize = () => setInnerHeight(window.innerHeight)
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const onOrderThumbClick = (order) => {
        props.history.push({
            pathname: '/simulador-atendimentos',
            state: {
                roomModelId: order.room._id,
                playcanvasId: order.room.playCanvas,
                roomCameras: order.room.cameras,
                orderId: order._id,
                orderName: order.customer.name,
                orderPhone: order.customer.phone,
                orderTitle: order.title,
                orderStatus: order.status,
                orderImageKey: order.images[0].imageKey,
                showInspirationModal: false
            }
        })
    }


    return (
        <>
            <Container innerHeight={innerHeight} backgroundColor={backgroundColor} fontColor={fontColor}>
                <div className="room-options">
                    {Orders.length > 0 && <h3>Ambientes</h3>}
                    {Orders.map(room => (
                        <AllThumb
                            id={room._id}
                            playCanvasId={room.PlaycanvasId}
                            title={room.title}
                            imgSrc={room.images[0].imageSrc}
                            onThumbClick={() => onOrderThumbClick(room)}
                        ></AllThumb>
                    ))}
                </div>
            </Container>
        </>
    )
}

export default AllList