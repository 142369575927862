import styled from "styled-components";

export const Text = styled.h3`
  font-size: 14px;
  color: ${(props) => props.fontColor};
  margin-top: 32px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
`;

export const Container = styled.div`
  padding: 24px;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 20%);
  border-radius: 15px;
  height: 60vh;

  h1 {
    margin: 0px;
    color: ${(props) => props.fontColor};
  }

  input {
    padding: 4px 8px;
    border-radius: 15px;
    border: 1px #bebebe solid;
  }

  .users {
    border: 1px solid #eaeaea;
    border-radius: 15px;
    height: calc(100% - 56px);
    display: inline-flex;
    width: 100%;
  }

  .userList {
    padding: 24px;
    height: 100%;
    width: 100%;
  }

  .title-new {
    display: inline-flex;
    margin-bottom: 12px;
  }

  .new-btn {
    padding: 6px 18px;
    border-radius: 10px;
    border: none;
    margin: 0px 12px;
    font-size: 14px;
    /* box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 20%); */
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #fff;
    background-color: #32cd32;
  }

  .new-btn :hover {
    opacity: 0.7;
  }

  .lists {
    display: flex;
    margin-top: 14px;
    border: 1px solid lightgray;
    border-radius: 15px;
    height: calc(100% - 42px);
  }

  .brands {
    width: 212px;
  }

  .brands h3 {
    padding: 12px;
    border-bottom: 1px solid lightgray;
    font-size: 14px;
    max-height: 44px;
  }

  .brand-list {
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 52px);
    padding: 12px;
  }

  .brands button {
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: none;
    margin: 2px 0px;
  }

  .brands input {
    margin-right: 6px;
  }

  .material-list {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 8px;
    height: auto;
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 12px;
    max-height: calc(100% - 48px);
  }

  .material-list-header h3 {
    margin-top: 14%;
  }

  .material-list-header {
    border-left: 1px solid lightgray;
    width: 100%;
    height: 100%;
  }

  .material-list-header input {
    margin: 0px 4px;
  }

  .selecionar-todos-checkbox {
    border-bottom: 1px solid lightgray;
    padding: 8px 16px;
    text-align: end;
    max-height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .list__item {
    border-radius: 8px;
    min-height: max-content;
    padding-bottom: 8px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    background-color: ${(props) => props.backgroundColor};
    border: none;
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;

    span {
      position: absolute;
      background-color: #0048ff;
      color: #dbd9d9;
      font-size: 10px;
      padding: 5px;
      border-radius: 9999px;
      box-shadow: 2px 2px 6px 2px #00000052;
      top: 4px;
      left: 4px;
      width: 22px;
      height: 22px;

      :hover {
        opacity: 0.8;
        transition: 0.2s;
      }
    }
  }

  .list__item__img {
    width: 100%;
    height: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .list__item__title {
    height: auto;
    max-height: 30px;
    font-size: 11px;
    color: ${(props) => props.fontColor};
    /* margin: .2rem .6rem auto .6rem; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .list__item__text {
    color: ${(props) => props.fontColor};
    font-size: 10px;
    margin: auto 0.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .checkbox {
    position: absolute;
    z-index: 2;
    margin: 12px;
    right: 4px;
    top: 4px;
  }

  .checkbox input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox .checkmark {
    display: block;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 2rem;
  }

  .checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .checkbox input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  @media (min-width: 1367) {
    .material-list {
      grid-template-columns: repeat(12, 1fr);
    }

    .form {
      display: contents;
      width: 50%;
      float: left;
    }

    form.form {
      margin-right: 20px;
    }

    .space {
      display: inline;
      padding: 5px;
    }
  }
`;

export const ButtonGroup = styled.div``;

export const Button = styled.div``;
