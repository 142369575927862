import { call, put, all, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import * as types from "../types";
import api from "../../../services/api";

function* getUserRooms({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/order?user=${payload.user}&contract=${payload.contract}`
    );
    /* const response = yield call(api.get, '/order/user/' + payload) */
    yield put(actions.getUserRoomsSuccess(response));
  } catch (e) {
    console.log(e);
  }
}

function* saveUserRooms({ payload }) {
  try {
    yield put(actions.saveUserRoomsData(payload));
  } catch (e) {
    console.log(e);
  }
}

export default all([
  takeEvery(types.GET_USER_ROOMS, getUserRooms),
  takeEvery(types.SAVE_USER_ROOMS, saveUserRooms),
]);
