import styled from 'styled-components'

export const OfflineDiv = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  width: fit-content;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  background-color: ${props => props.backgroundColor};
  position: absolute;
  bottom: 50px;
  align-self: center;
  z-index: 8;
  font-size: 11px;
  font-weight: 500;
  padding-left: 16px;
  border-radius: 10px;
  opacity: .9;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);

  i.fa-wifi {
    position: relative;
    margin-left: 12px;
    font-size: 12px;
  }

  i.fa-slash {
    position: relative;
    right: 15px;
    z-index: 9;
    font-size: 12px;
  }
`