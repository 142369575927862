import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { contractContext } from "../../../Contexts/contractContext";
import * as roomsActions from "../../../store/modules/rooms/actions";
import RoomThumb from "./components/RoomThumb";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import GenericModal from "../../../components/GenericModal/";
import api from "../../../services/api";
import FormControl from "@material-ui/core/FormControl";
import SingleRoom from "./components/SingleRoom";

// Styles
// atualmente os styles de tudo estão nesse styles principal - vou estudar um pouco mais sobre react-components para transferir tudo certinho para o styles do componente certo sem chance de causar erro.
import { Container, SingleRoomHeader } from "./styles";
import Loader from "../../../components/Loader";

const Rooms = (props) => {
    const [rooms, setRooms] = useState([]);
    const [loadingRooms, setLoadingRooms] = useState(false);

    const user = useSelector((state) => state.loginReducer.user);

    const { currentContract, setCurrentContract } = useContext(contractContext);
    const [data, setData] = useState([]);
    const [selectAllCategorys, setSelectAllCategorys] = useState([
        {
            category: "",
            select: false,
        },
    ]);
    const [selectAllContracts, setSelectAllContracts] = useState(false);

    const [session, setSession] = useState("roomsList");
    const [selectedRoom, setSelectedRoom] = useState();

    const [contractModalState, setContractModalState] = useState(false);
    const [contractList, setContractList] = useState([]);
    const [selectedContracts, setSelectedContracts] = useState([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const brandColor = useSelector(
        (state) => state.contractReducer.contract.config.brandColor
    );
    const backgroundColor = useSelector(
        (state) => state.contractReducer.contract.config.backgroundColor
    );
    const fontColor = useSelector(
        (state) => state.contractReducer.contract.config.fontColor
    );

    const dispatch = useDispatch();

    const handleSessionClick = (session) => {
        setSession(session);
    };

    const loadContracts = () => {
        api.get(`contract/reduced`).then(({ data }) => {
            if (selectedRoom) {
                const listHelper = data.contracts
                    .map((c) => {
                        return {
                            value: c._id,
                            label: c.name,
                            rooms: c.rooms,
                            isChecked: c.rooms.includes(selectedRoom._id),
                        };
                    })
                    .sort(function (a, b) {
                        if (a.label > b.label) {
                            return 1;
                        }
                        if (a.label < b.label) {
                            return -1;
                        }
                        //  a must be equal to b
                        return 0;
                    });
                setSelectedContracts(
                    listHelper
                        .filter((contract) => contract.isChecked)
                        .map((contract) => {
                            return contract.value;
                        })
                );

                setContractList(listHelper);
            } else {
                const listHelper = data.contracts
                    .map((c) => {
                        return {
                            value: c._id,
                            label: c.name,
                            rooms: c.rooms,
                            isChecked: false,
                        };
                    })
                    .sort(function (a, b) {
                        if (a.label > b.label) {
                            return 1;
                        }
                        if (a.label < b.label) {
                            return -1;
                        }
                        //  a must be equal to b
                        return 0;
                    });

                setContractList(listHelper);
            }
        });
    };

    useEffect(() => {
        setSelectAllContracts(
            contractList.every((contract) => contract.isChecked)
        );
    }, [contractList]);

    useEffect(() => {
        loadContracts();
    }, [selectedRoom]);


    useEffect(() => {
        setLoadingRooms(true);
        api.get("/room").then((res) => {
            const roomsArray = res.data.rooms;

            setRooms(roomsArray);
        });
    }, []);

    useEffect(() => {
        if (rooms.length > 0) {
            const categorys = [
                ...new Set(
                    rooms.map((r) => {
                        return r.type.description;
                    })
                ),
            ].map((category) => {
                return {
                    description: category,
                    rooms: rooms.filter(
                        (room) => room.type.description === category
                    ),
                };
            });

            const isAllinCategorys = [];
            categorys.map((category) => {
                const isAllInContract = category.rooms.every((room) =>
                    currentContract.rooms.includes(room._id)
                );

                isAllinCategorys.push({
                    category: category.description,
                    select: isAllInContract,
                });
            });
            setSelectAllCategorys(isAllinCategorys);
            setData(categorys);

            setLoadingRooms(false);
        }
    }, [rooms]);

    useEffect(() => {
        const isAllinCategorys = [];
        data.map((category) => {
            const isAllInContract = category.rooms.every((room) =>
                currentContract.rooms.includes(room._id)
            );

            isAllinCategorys.push({
                category: category.description,
                select: isAllInContract,
            });
        });
        setSelectAllCategorys(isAllinCategorys);
    }, [currentContract.rooms]);

    const handleEditClick = (room) => {
        setSelectedRoom(room);
        setSession("singleRoom");
    };

    const handleSelectedContracts = (contractId, checked) => {
        let cHelper = selectedContracts;
        if (checked) {
            cHelper.push(contractId);
        } else {
            cHelper.splice(cHelper.indexOf(contractId), 1);
        }

        setSelectedContracts(cHelper);
    };

    const handleCheckContract = (target) => {
        let cHelper = [...new Set(contractList)];

        cHelper.forEach((contract) => {
            if (contract.value == target.value) {
                handleSelectedContracts(contract.value, target.checked);
                return (contract.isChecked = target.checked);
            }
        });

        setContractList(cHelper);
    };

    const handleSelectAll = (checked, category) => {
        const newCategory = selectAllCategorys.map((check, index) => {
            if (check.category === category) {
                return {
                    category: check.category,
                    select: checked,
                };
            } else {
                return check;
            }
        });

        const constractRooms = currentContract.rooms;
        const rooms = data.find(
            (roomCateogory) => roomCateogory.description === category
        ).rooms;
        rooms.forEach((room) => {
            if (checked) {
                if (!constractRooms.includes(room._id))
                    constractRooms.push(room._id);
            } else {
                if (constractRooms.includes(room._id))
                    constractRooms.splice(constractRooms.indexOf(room._id), 1);
            }
        });
        setCurrentContract((prevContract) => {
            return { ...prevContract, rooms: constractRooms };
        });

        setSelectAllCategorys(newCategory);
    };

    const handleOnClickAddToContracts = (event) => {
        api.put(`contract/room/${selectedRoom._id}`, {
            contracts: selectedContracts,
        }).then((data) => {
            if (selectedContracts.includes(currentContract._id)) {
                if (!currentContract.rooms.includes(selectedRoom._id)) {
                    currentContract.rooms.push(selectedRoom._id);
                    setCurrentContract(currentContract);
                }
            } else {
                if (currentContract.rooms.includes(selectedRoom._id)) {
                    setCurrentContract((prevCurrentContract) => {
                        return {
                            ...prevCurrentContract,
                            rooms: currentContract.rooms.filter(
                                (room) => selectedRoom._id !== room
                            ),
                        };
                    });
                }
            }
            setHasSubmitted(true);
        });
    };

    const handleSelectAllContracts = (e) => {
        const newContracList = contractList.map((c) => {
            return { ...c, isChecked: e.target.checked };
        });
        setSelectedContracts(
            newContracList
                .filter((contract) => contract.isChecked)
                .map((contract) => {
                    return contract.value;
                })
        );
        setContractList(newContracList);
    };
    return (
        <>
            {loadingRooms ? (
                <Loader></Loader>
            ) : (
                <Container
                    backgroundColor={backgroundColor}
                    fontColor={fontColor}
                    brandColor={brandColor}
                >
                    <div className="rooms-header">
                        <h1>Ambientes</h1>

                        {(session === "roomsList" && user.type === "admin" && (
                            <button
                                className="btn-novo-ambiente"
                                onClick={() => {
                                    setSelectedRoom();
                                    handleSessionClick("singleRoom");
                                }}
                            >
                                Novo Ambiente
                            </button>
                        )) ||
                            (session === "singleRoom" &&
                                user.type === "admin" && (
                                    <SingleRoomHeader>
                                        <button
                                            className="btn-novo-ambiente"
                                            onClick={() => {
                                                setContractModalState(true);
                                            }}
                                        >
                                            Vincular a contrato
                                        </button>
                                        <button
                                            className="btn-novo-ambiente"
                                            type="button"
                                            onClick={() => {
                                                setSession("roomsList");
                                            }}
                                        >
                                            Voltar
                                        </button>
                                    </SingleRoomHeader>
                                ))}
                    </div>

                    <div className="rooms">
                        {session === "singleRoom" ? (
                            <SingleRoom
                                room={selectedRoom}
                                setRooms={setRooms}
                            />
                        ) : (
                            session === "roomsList" &&
                            data &&
                            data.map((category) => (
                                <>
                                    <div className="category-title">
                                        <h2>{category.description}</h2>
                                        <div className="selecionar-todos-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={
                                                    selectAllCategorys.find(
                                                        (selected) => {
                                                            return (
                                                                selected.category ===
                                                                category.description
                                                            );
                                                        }
                                                    ).select
                                                }
                                                onChange={(e) =>
                                                    handleSelectAll(
                                                        e.target.checked,
                                                        category.description
                                                    )
                                                }
                                            />
                                            Selecionar todos
                                        </div>
                                    </div>
                                    <div className="carousel-wrapper">
                                        <CarouselProvider
                                            naturalSlideWidth={
                                                window.innerWidth * 0.18
                                            }
                                            naturalSlideHeight={300}
                                            totalSlides={category.rooms.length}
                                            visibleSlides={4}
                                            step={3}
                                            isIntrinsicHeight
                                        >
                                            <Slider
                                                className="thumb-slider"
                                                classNameAnimation="slider"
                                            >
                                                {category.rooms.map(
                                                    (room, index) => (
                                                        <Slide
                                                            key={index}
                                                            index={index}
                                                        >
                                                            <RoomThumb
                                                                roomId={
                                                                    room._id
                                                                }
                                                                data={data}
                                                                title={
                                                                    room.title
                                                                }
                                                                imgSrc={
                                                                    room.thumbPath
                                                                }
                                                                changeSession={
                                                                    setSession
                                                                }
                                                                category={
                                                                    category.description
                                                                }
                                                                room={room}
                                                                checked={
                                                                    currentContract.rooms &&
                                                                    currentContract.rooms.includes(
                                                                        room._id
                                                                    )
                                                                }
                                                                editClick={() =>
                                                                    handleEditClick(
                                                                        room
                                                                    )
                                                                }
                                                                checkAll={
                                                                    selectAllCategorys
                                                                }
                                                                setCheckALl={
                                                                    setSelectAllCategorys
                                                                }
                                                            />
                                                        </Slide>
                                                    )
                                                )}
                                            </Slider>
                                            <></>
                                        </CarouselProvider>
                                    </div>
                                </>
                            ))
                        )}

                        <GenericModal
                            display={contractModalState}
                            onCloseClick={() => setContractModalState(false)}
                            arrowYPosition={"28vh"}
                            arrowXPosition={"6vw"}
                            mainContent={
                                <>
                                    {hasSubmitted ? (
                                        <>
                                            <h3>Vinculado com sucesso</h3>
                                            <FormControl className="contrato-selector">
                                                <div className="bind-contract-container">
                                                    <p>
                                                        Contratos vinculados com
                                                        sucesso.
                                                    </p>
                                                    <button
                                                        className="btn-vincular-contratos"
                                                        onClick={() => {
                                                            setContractModalState(
                                                                false
                                                            );
                                                            setHasSubmitted(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        Fechar
                                                    </button>
                                                </div>
                                            </FormControl>
                                        </>
                                    ) : (
                                        <>
                                            <h3>Vincular a Contratos</h3>
                                            <FormControl className="contrato-selector">
                                                <label>
                                                    Escolha os contratos a qual
                                                    esse ambiente deve ser
                                                    vinculado.
                                                </label>
                                                <div className="bind-contract-container">
                                                    <div className="rooms">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    selectAllContracts
                                                                }
                                                                onChange={
                                                                    handleSelectAllContracts
                                                                }
                                                            ></input>
                                                            Selecionar Todos
                                                        </label>
                                                        {contractList.map(
                                                            (contract) => {
                                                                return (
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            key={
                                                                                contract._id
                                                                            }
                                                                            value={
                                                                                contract.value
                                                                            }
                                                                            checked={
                                                                                contract.isChecked
                                                                            }
                                                                            onChange={(
                                                                                t
                                                                            ) =>
                                                                                handleCheckContract(
                                                                                    t.target
                                                                                )
                                                                            }
                                                                        ></input>
                                                                        {
                                                                            contract.label
                                                                        }
                                                                    </label>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                    <button
                                                        className="btn-vincular-contratos"
                                                        onClick={
                                                            handleOnClickAddToContracts
                                                        }
                                                    >
                                                        Vincular a Contratos
                                                    </button>
                                                </div>
                                            </FormControl>
                                        </>
                                    )}
                                </>
                            }
                        ></GenericModal>
                    </div>
                </Container>
            )}
        </>
    );
};

export default Rooms;
