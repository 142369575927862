import * as types from "../types";

export function getContract(payload) {
  return {
    type: types.GET_CONTRACT,
    payload,
  };
}

export function getContractSuccess(payload) {
  return {
    type: types.GET_CONTRACT_SUCCESS,
    payload,
  };
}

export function getAllContracts(payload) {
  return {
    type: types.GET_ALL_CONTRACTS,
    payload,
  };
}

export function getAllContractsSuccess(payload) {
  return {
    type: types.GET_ALL_CONTRACTS_SUCCESS,
    payload,
  };
}

export function getContractRooms(payload) {
  return {
    type: types.GET_CONTRACT_ROOMS,
    payload,
  };
}

export function getContractRoomsSuccess(payload) {
  return {
    type: types.GET_CONTRACT_ROOMS_SUCCESS,
    payload,
  };
}

export function getContractMaterials(payload) {
  return {
    type: types.GET_CONTRACT_MATERIALS,
    payload,
  };
}

export function getContractMaterialsSuccess(payload) {
  return {
    type: types.GET_CONTRACT_MATERIALS_SUCCESS,
    payload,
  };
}

export function getContractTemplates(payload) {
  return {
    type: types.GET_CONTRACT_TEMPLATES,
    payload,
  };
}

export function getContractTemplatesSuccess(payload) {
  return {
    type: types.GET_CONTRACT_TEMPLATES_SUCCESS,
    payload,
  };
}

export function getContractUsers(payload) {
  return {
    type: types.GET_CONTRACT_USERS,
    payload,
  };
}

export function getContractUsersSuccess(payload) {
  return {
    type: types.GET_CONTRACT_USERS_SUCCESS,
    payload,
  };
}
