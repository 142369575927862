import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  display: 'flex';
  flex: 1;
  flex-wrap: no-wrap;
  height: auto;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.backgroundColor};
  z-index: ${props => props.onboarding === 4 ? '4' : '3'};
  font-family: 'Montserrat', sans-serif;

  @media only screen and (min-width: 1024px) {
    width: 300px !important;

    z-index: ${props => props.onboarding === 4 ? '5' : '3'};

    div.navbar {
      width: 300px !important;
      top: 40px !important;
    }

    i {
      display: none !important;
    }

    div.navbar div:hover {
      opacity: .8;
    }

    div.navbar div.selected {
      border-bottom: none;
      color: ${props => props.brandColor};
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  i {
    left: calc(50vw - 10px);
    position: fixed;
    font-size: 32px;
    color: ${props => props.backgroundColor};
    bottom: 48px;
    cursor: pointer;
  }

  div.navbar {
    position: fixed;
    bottom: 0;
    display: flex;
    flex: 1;
    flex-wrap: no-wrap;
    height: 40px;
    width: 100vw;
    align-items: center;
    background-color: ${props => props.backgroundColor};
  }

  div.navbar div {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: ${props => props.fontColor};
    font-size: 21px;
    cursor: pointer;
  }

  div.navbar div h3 {
    font-size: 12px;
  }

  div.navbar div.selected {
    border-top: 2px solid ${props => props.brandColor};
    color: ${props => props.brandColor};
  }
`