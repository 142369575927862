import * as types from "../types";

const initialState = {
  contract: {
    name: "CRIA Decor",
    isLoading: true,
    contractId: "",
    initiatedOrders: [],
    whatsappMessagesSent: [],
    config: {
      brandColor: "#0048ff",
      brandContrastColor: "#FFF",
      backgroundColor: "#FFF",
      fontColor: "#222",
      alertColor: "#FF220C",
      logoPath:
        "https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/logo-cria.png",
    },
    rooms: [],
  },
  contractMaterials: {
    isLoading: true,
    data: [],
  },
  contractRooms: {
    isLoading: true,
    data: [],
  },
  contractTemplates: {
    isLoading: true,
    data: [],
  },
  contractUsers: {
    isLoading: true,
    data: [],
  },
  contracts: {
    isLoading: true,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTRACT_SUCCESS: {
      const newState = {
        ...state,
        contract: {
          ...state.contract,
          isLoading: false,
          contractId: action.payload.data.contract._id,
          name: action.payload.data.contract.name,
          initiatedOrders: action.payload,
          rooms: action.payload.data.contract.rooms,
          data: action.payload.data.contract,
        },
      };
      /*
            newState.contract.isLoading = false
            newState.contract.contractId = action.payload.data.contract._id
            newState.contract.name = action.payload.data.contract.name
            newState.contract.initiatedOrders = action.payload
            newState.contract.rooms = action.payload.data.contract.rooms
            */

      if (action.payload.data.contract.whiteLabel) {
        if (action.payload.data.contract.whiteLabel.logoPath)
          newState.contract.config.logoPath =
            action.payload.data.contract.whiteLabel.logoPath;
        if (action.payload.data.contract.whiteLabel.brandColor)
          newState.contract.config.brandColor =
            action.payload.data.contract.whiteLabel.brandColor;
        if (action.payload.data.contract.whiteLabel.brandContrastColor)
          newState.contract.config.brandContrastColor =
            action.payload.data.contract.whiteLabel.brandContrastColor;
        if (action.payload.data.contract.whiteLabel.alertColor)
          newState.contract.config.alertColor =
            action.payload.data.contract.whiteLabel.alertColor;
        if (action.payload.data.contract.whiteLabel.backgroundColor)
          newState.contract.config.backgroundColor =
            action.payload.data.contract.whiteLabel.backgroundColor;
        if (action.payload.data.contract.whiteLabel.fontColor)
          newState.contract.config.fontColor =
            action.payload.data.contract.whiteLabel.fontColor;
      }

      /* almost full data to avoid some gets api calls */
      //newState.contract.data = action.payload.data.contract

      return newState;
    }
    case types.GET_ALL_CONTRACTS_SUCCESS: {
      const newState = { ...state };

      newState.contracts.data = action.payload.data.contracts;
      newState.contracts.isLoading = false;

      return newState;
    }
    case types.GET_CONTRACT_ROOMS_SUCCESS: {
      const newState = { ...state };
      const arr1 = action.payload.data.rooms;
      let arr2 = [];
      arr1.forEach((room, index) => {
        const helper = arr2.findIndex(
          (item) => item.title === room.type.description
        );
        helper != -1
          ? arr2[helper].rooms.push(room)
          : arr2.push({
              _id: room.type._id,
              title: room.type.description,
              thumbPath: room.type.thumbPath,
              mobileThumbPath: room.type.mobileThumbPath,
              rooms: [room],
            });
      });
      newState.contractRooms.data = arr2;
      newState.contractRooms.isLoading = false;

      return newState;
    }
    case types.GET_CONTRACT_MATERIALS_SUCCESS: {
      const newState = { ...state };
      const arr1 = action.payload.data.materials;

      let arr2 = [];
      arr1.forEach((material, index) => {
        const helper = arr2.findIndex(
          (item) => item.description === material.type.description
        );
        if (helper != -1) {
          const helper2 = arr2[helper].reproduction.findIndex(
            (item) => item.description === material.reproduction.description
          );
          helper2 != -1
            ? arr2[helper].reproduction[helper2].materials.push(material)
            : arr2[helper].reproduction.push({
                description: material.reproduction.description,
                thumb: material.reproduction.thumbPath,
                materials: [material],
              });
        } else {
          arr2.push({
            description: material.type.description,
            reproduction: [
              {
                description: material.reproduction.description,
                thumb: material.reproduction.thumbPath,
                materials: [material],
              },
            ],
          });
        }
      });

      newState.contractMaterials.data = arr2;
      newState.contractMaterials.isLoading = false;
      return newState;
    }
    case types.GET_CONTRACT_TEMPLATES_SUCCESS: {
      const newState = { ...state };
      const arr1 = action.payload.data.templates;
      let arr2 = [];
      arr1.forEach((template, index) => {
        const helper = arr2.findIndex(
          (item) => item.description === template.style.description
        );
        helper != -1
          ? arr2[helper].templates.push(template)
          : arr2.push({
              _id: template.style._id,
              description: template.style.description,
              templates: [template],
            });
      });
      newState.contractTemplates.data = arr2;
      newState.contractTemplates.isLoading = false;
      return newState;
    }
    case types.GET_CONTRACT_USERS_SUCCESS: {
      const newState = { ...state };
      newState.contractUsers.data = action.payload.data.users;
      newState.contractUsers.isLoading = false;

      return newState;
    }
    default: {
      return state;
    }
  }
};
