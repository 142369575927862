import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  font-family: "Montserrat", sans-serif;
  margin-top: 39px;
  flex-direction: column;
  height: inherit;
  overflow-y: auto;

  div.datas {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-left: 60px;
    margin-right: 40px;
    align-items: center;
  }

  .iconAjust i {
    font-weight: 900 !important;
  }

  .painel-h6 {
    width: 100%;
    color: ${(props) => props.fontColor};
    margin-bottom: 10px;
    font-size: 15px;
  }

  .painel-h6 font {
    color: #7e7e7e;
  }

  .total-registro {
    color: ${(props) => props.fontColor};
  }

  .dataTitle {
    color: ${(props) => props.fontColor};
  }

  .dataGerais .dataTitle {
    display: contents;
  }

  .titulo-2 h6 {
    width: 100% !important;
  }

  .img-loading {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .img-loading img {
    width: 200px;
  }

  .datas {
    justify-content: flex-end;
    margin: 15px -15px !important;
  }

  .label {
    margin-top: 20px;
    text-align: center;
    width: 88%;
    display: block;
  }

  @media screen and (max-width: 1366px) {
    .label {
      margin-top: 10px;
    }
  }

  .gold {
    color: #d7c01e !important;
  }

  .silver {
    color: #bfbfbf !important;
  }

  .bronze {
    color: #c77c2f !important;
  }

  .totalizadores {
    padding-top: 20px;
    display: block;
    width: 95%;
  }

  .totalizadores-item {
    text-align: center;
  }

  .totalizadores .type-1 {
    width: 100%;
  }

  .totalizadores .type-2 {
    width: 50%;
    float: left;
  }

  .totalizadores-item {
    padding: 40px 0 !important;
  }

  .totalizadores-item p {
    margin: 0;
    font-size: 40px;
    margin-top: -10px;
    color: #959595;
    font-weight: 900;
  }

  .painel-class {
    border-radius: 20px;
    height: 340px;
    margin-right: 15px;
    width: 33%;
    background-color: #f1f1f1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-shadow: 2px 2px 2px 0px #0000002e;
  }

  @media screen and (max-width: 1366px) {
    .painel-class {
      height: 230px;
    }

    .totalizadores-item {
      padding: 20px 0 !important;
      font-size: 10px;
    }

    .totalizadores-item p {
      font-size: 25px;
    }
  }

  .painel-class:last-child {
    margin-right: 0px;
  }

  .more-painels .mais {
    background-color: #383838;
  }

  .more-painels .mais h6 {
    color: white !important;
  }

  .more-painels {
    display: flex;
  }

  .more-painels .table-class {
    width: 90%;
    overflow: auto;
    height: 84%;
  }

  .table-class::-webkit-scrollbar-thumb {
    background: #838383;
    border-radius: 20px;
  }

  .table-class table {
    width: 100%;
    text-align: left;
  }

  .table-class table th {
    padding: 7px 5px;
  }

  .more-painels .mais table,
  .more-painels .mais table * {
    color: white;
  }

  .more-painels .mais table {
    border-collapse: collapse;
  }

  .more-painels .mais table tbody * {
    font-weight: 100;
  }

  .more-painels .mais table tr:hover,
  .more-painels .mais table tr:hover * {
    background-color: #767676 !important;
    border-color: inherit !important;
    border-style: solid !important;
    border-width: 0 !important;
  }

  .more-painels .LastAccess {
    text-align: right;
  }

  .more-painels .recentes .item-table {
    background-position-y: 50% !important;
    background-size: 100% !important;
    border-radius: 15px;
  }

  .more-painels .recentes .item-table * {
    color: white;
    font-weight: 100;
  }

  .more-painels .recentes .item-table .item-table-user {
    font-size: 12px;
    margin-bottom: 0;
  }

  .more-painels .recentes .item-table .item-table-status {
    width: 115px;
    font-size: 10px;
  }

  .more-painels .recentes .item-table .item-table-status p {
    text-align: center;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: -10px;
    padding: 3px;
    font-weight: bold;
  }

  .item-status-open p {
    background-color: orange;
  }

  .item-status-closed p {
    background-color: green;
  }

  .item-status-cancelled p {
    background-color: red;
  }

  .more-painels .recentes .item-table .item-table-info {
    font-size: 15px;
  }

  .more-painels .recentes .item-table .item-table-user {
    font-size: 10px;
  }

  .more-painels .table-class .space th {
    padding: 5px;
  }

  form.form {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }

  form.form label {
    font-weight: bold;
    font-size: 10px;
    margin-left: 8px;
    color: ${(props) => props.fontColor};
  }

  form.form input {
    margin-top: 2px;
    width: 155px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid rgba(98, 98, 98, 0.5);
    padding-left: 8px;
  }

  @media screen and (max-width: 440px) {
    div.datas {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      margin-left: 30px;
      margin-right: 10px;
      align-items: center;
    }

    form.form {
      display: flex;
      flex-direction: column;
      margin-right: 15px;
    }

    form.form input {
      width: 126px;
      height: 30px;
      border-radius: 8px;
      border: 1px solid rgba(98, 98, 98, 0.5);
      padding-left: 8px;
    }
  }

  .button-filter {
    width: 100px;
    border-radius: 5px;
    background-color: #00000099;
    border: 0;
    margin-top: 15px;
    padding: 8px;
    color: white;
  }

  .filtros-class .form button {
    background-color: #2196f3;
    width: 100px;
    margin-bottom: -10px;
    height: 34px;
    margin-top: 2px;
    color: white;
  }

  .margin-class-top-10 {
    margin-top: 25px;
  }

  .filtros-class > div {
    margin-left: -10px !important;
  }

  .filtros-class label {
    font-size: 12px;
    margin-top: 3px;
    font-weight: 100 !important;
    color: ${(props) => props.fontColor};
  }

  .filtros-class input {
    border-radius: 80px !important;
    padding: 4px 8px;
    border: 1px #bebebe solid !important;
    height: auto !important;
    margin-top: 5px !important;
  }

  .filtros-class {
    display: flex;
    margin-left: 10px;
    margin-top: -12px;
    white-space: nowrap;
    overflow: hidden;
    padding: 10px 0 0 0;
  }

  .filtros-true {
    animation-name: filter-anim;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  @keyframes filter-anim {
    from {
      width: 0%;
    }
    to {
      width: 50%;
    }
  }

  .filtros-false {
    animation-name: filter-anim2;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  @keyframes filter-anim2 {
    from {
      width: 50%;
    }
    to {
      width: 0%;
    }
  }

  .filtros-class .form {
    margin-top: -10px;
    margin-left: 10px;
  }

  @media screen and (max-width: 1366px) {
    @keyframes filter-anim {
      from {
        width: 0%;
      }
      to {
        width: 60%;
      }
    }

    @keyframes filter-anim2 {
      from {
        width: 60%;
      }
      to {
        width: 0%;
      }
    }

    .grafico-container {
      margin-left: 13% !important;
    }

    .card-body {
      height: 180px !important;
    }

    .loading {
      height: 195px !important;
    }
  }

  .data-visible {
    overflow: hidden;
    white-space: nowrap;
    animation-name: filter-anim3;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  .data-hidden {
    overflow: hidden;
    width: 150px;
    white-space: nowrap;
    animation-name: filter-anim4;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  @keyframes filter-anim3 {
    from {
      width: 0px;
    }
    to {
      width: 164px;
    }
  }

  @keyframes filter-anim4 {
    from {
      width: 155px;
    }
    to {
      width: 0px;
    }
  }
`;

export const Graficos = styled.div`
  display: flex;
  flex-wrap: wrap;

  height: inherit;

  .loading {
    height: 300px;
  }

  div.grafico-container {
    background: ${(props) => props.backgroundColor};
    border-radius: 0px;
    padding: 0 55px;
    width: 90%;
    margin-left: 10%;
    height: auto;
  }

  .card-body {
    height: 284px;
    box-shadow: 2px 2px 2px 0px #0000002e;
    padding: 10px;
  }

  .dataGerais p {
    color: #7e7e7e;
    margin-left: 10px;
  }

  .dataGerais {
    font-size: 15px;
    width: 100% !important;
    float: inherit !important;
    display: flex;
    margin-bottom: -5px;
  }

  .dataGerais button {
    border-radius: 22px;
    font-size: 12px;
    color: #525252;
    border: 0;
    margin: -5px 0 17px 10px;
    width: 35px;
    background-color: #d5d5d5;
    height: 25px;
  }

  .titulo-2 h6 {
  }

  .painel h6 {
    font-size: 14px;
  }

  .header {
    padding: 15px 0 10px 0;
  }

  .painel-materials {
    height: 80%;
  }

  .painel-class h6 {
    color: gray;
    font-size: 14px;
    width: 100%;
    padding: 15px 20px 10px;
    border-bottom: 1px solid #dfdfdf;
    text-align: center;
  }

  .painel .text-right {
    text-align: right;
  }

  div.painel {
    width: 800px;
    background: ${(props) => props.backgroundColor};
    padding-left: 6px;
    transition: 0.1s;
  }

  div.painel div.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.painel div div.loader {
    border: 5px solid #d8d8d8;
    border-top: 5px solid #0048ff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width: 890px) {
    .menu-lateral {
      display: flex !important;
      top: inherit !important;
      bottom: 0 !important;
      width: 100% !important;
      height: 70px !important;
      flex-direction: row !important;
    }

    .menu-lateral .button-class {
      border: 0 !important;
      width: 50px;
    }
  }

  @media screen and (min-width: 1921px) {
    div.painel {
      width: auto;
    }

    div.painel .card-body {
      margin-bottom: 10px;
      background: #f1f1f1;
      border-radius: 15px;
    }
  }

  @media screen and (max-width: 1921px) {
    div.painel {
      width: 100%;
      padding-left: 6px;
      transition: 0.1s;
      padding: 10px 20px 20px 20px;
      border-radius: 15px;
    }

    div.painel div.loading {
    }

    div.painel .card-body {
      margin-bottom: 10px;
      background: #f1f1f1;
      border-radius: 15px;
    }
  }

  @media screen and (max-width: 1755px) {
    div.painel {
      padding-left: 6px;
      transition: 0.1s;
    }
  }

  @media screen and (max-width: 1570px) {
    div.painel {
      padding-left: 6px;
      transition: 0.1s;
    }
  }

  @media screen and (max-width: 1380px) {
    div.painel {
      padding-left: 6px;
      transition: 0.1s;
    }
  }

  @media screen and (max-width: 829px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div.grafico-container {
      background: #d8d8d8;
      border-radius: 6px;
      padding: 10px;
    }

    div.painel {
      width: 750px;
      padding-left: 6px;
      transition: 0.1s;
    }

    div.painel div.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 350px;
    }

    div.painel div div.loader {
      width: 70px;
      height: 70px;
    }
  }

  @media screen and (max-width: 773px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div.grafico-container {
      background: #d8d8d8;
      border-radius: 6px;
      padding: 10px;
    }

    div.painel {
      width: 700px;
      padding-left: 6px;
      transition: 0.1s;
    }

    div.painel div.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
    }

    div.painel div div.loader {
      width: 70px;
      height: 70px;
    }
  }

  @media screen and (max-width: 729px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div.grafico-container {
      background: #d8d8d8;
      border-radius: 6px;
      padding: 10px;
    }

    div.painel {
      width: 650px;
      background: #fff;
      padding-left: 6px;
      transition: 0.1s;
    }

    div.painel div.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
    }
  }

  @media screen and (max-width: 677px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div.grafico-container {
      background: #d8d8d8;
      border-radius: 6px;
      padding: 10px;
    }

    div.painel {
      width: 600px;
      padding-left: 6px;
      transition: 0.1s;
    }

    div.painel div.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
    }

    div.painel div div.loader {
      width: 60px;
      height: 60px;
    }
  }

  @media screen and (max-width: 629px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div.grafico-container {
      background: #d8d8d8;
      border-radius: 6px;
      padding: 10px;
    }

    div.painel {
      width: 550px;
      padding-left: 6px;
      transition: 0.1s;
    }

    div.painel div.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 250px;
    }

    div.painel div div.loader {
      width: 60px;
      height: 60px;
    }
  }

  @media screen and (max-width: 581px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div.grafico-container {
      background: #d8d8d8;
      border-radius: 6px;
      padding: 10px;
    }

    div.painel {
      width: 500px;
      padding-left: 6px;
      transition: 0.1s;
    }
  }

  @media screen and (max-width: 529px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div.grafico-container {
      background: #d8d8d8;
      border-radius: 6px;
      padding: 10px;
    }

    div.painel {
      width: 450px;
      padding-left: 6px;
      transition: 0.1s;
    }

    div.painel div.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 222px;
    }

    div.painel div div.loader {
      width: 50px;
      height: 50px;
    }
  }

  @media screen and (max-width: 477px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div.grafico-container {
      background: #d8d8d8;
      border-radius: 6px;
      padding: 10px;
    }

    div.painel {
      width: 400px;
      padding-left: 6px;
      transition: 0.1s;
    }

    div.painel div.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
    }
  }

  @media screen and (max-width: 429px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div.grafico-container {
      background: #d8d8d8;
      border-radius: 6px;
      padding: 10px;
    }

    div.painel {
      width: 370px;
      padding-left: 6px;
      transition: 0.1s;
    }

    div.painel div.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 185px;
    }

    div.painel div div.loader {
      width: 50px;
      height: 50px;
    }
  }

  @media screen and (max-width: 377px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div.grafico-container {
      background: #d8d8d8;
      border-radius: 6px;
      padding: 10px;
    }

    div.painel {
      width: 300px;
      padding-left: 6px;
      transition: 0.1s;
    }

    div.painel div.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 147px;
    }

    div.painel div div.loader {
      width: 50px;
      height: 50px;
    }
  }
`;

export const Gerar = styled.div`
  margin-bottom: 12px;
  transition: 0.3s;
  height: inherit;

  button.card1 {
    display: flex;
    width: 250px;
    height: 100px;
    background: linear-gradient(
        99.4deg,
        #04cb18 24.82%,
        rgba(0, 194, 255, 0) 114.94%
      ),
      #0bbe9e;
    border-radius: 29px;
    transition: 0.5s;
    border: none;
  }

  button.card1:hover {
    background: linear-gradient(
        99.4deg,
        #019a10 24.82%,
        rgba(0, 194, 255, 0) 114.94%
      ),
      #07584a;
    width: 320px;
    height: 120px;
  }

  button.card2 {
    display: flex;
    width: 250px;
    height: 100px;
    background: linear-gradient(
        99.57deg,
        rgba(36, 0, 255, 0) 10.43%,
        #e108e6 107.26%
      ),
      #fa00ff;
    border-radius: 29px;
    transition: 0.5s;
    border: none;
  }

  button.card2:hover {
    background: linear-gradient(
        99.57deg,
        rgba(34, 1, 238, 0) 10.43%,
        #ca09ce 107.26%
      ),
      #8c028e;
    width: 320px;
    height: 120px;
  }

  button.card3 {
    display: flex;
    width: 250px;
    height: 100px;
    background: linear-gradient(
        98.03deg,
        rgba(0, 6, 56, 0) 25.61%,
        #8ab0fa 101.8%
      ),
      #000aff;
    border-radius: 29px;
    transition: 0.5s;
    border: none;
  }

  button.card3:hover {
    background: linear-gradient(
        98.03deg,
        rgba(0, 6, 56, 0) 25.61%,
        #3479ff 101.8%
      ),
      #020544;
    width: 320px;
    height: 120px;
  }

  div.content-info {
    display: flex;
    flex-direction: column;
  }

  button p.textbtn0 {
    font-size: 24px;
    color: #ffffff;
    margin-top: 34px;
    margin-left: 40px;
    margin-right: 20px;
    transition: 0.2s;
  }

  button p.textbtn1 {
    font-size: 20px;
    color: #ffffff;
    margin-top: 37px;
    margin-left: 15px;
    transition: 0.2s;
  }

  button p.textbtn2 {
    font-size: 24px;
    color: #ffffff;
    margin-top: 34px;
    margin-left: 40px;
    transition: 0.2s;
  }

  button div i {
    display: flex;
    height: 100%;
  }

  button div i.fas {
    font-size: 54px;
    color: #ffffff;
    margin-top: 23px;
    margin-left: 12px;
    transition: 0.3s;
  }

  button.card1:hover div i.fas {
    font-size: 64px;
    margin-top: 30px;
    margin-left: 65px;
  }

  button.card2:hover div i.fas {
    font-size: 64px;
    margin-top: 30px;
    margin-left: 38px;
  }

  button div i.far {
    font-size: 54px;
    color: #ffffff;
    margin-top: 23px;
    margin-left: 20px;
    transition: 0.3s;
  }

  button.card3:hover div i.far {
    font-size: 64px;
    margin-top: 30px;
    margin-left: 50px;
  }

  button p.quantidade {
    font-size: 30px;
    color: #ffffff;
    margin-top: 10px;
    margin-left: 38px;
    transition: 0.3s;
    opacity: 0;
  }

  button.card1:hover p.quantidade {
    opacity: 1;
  }

  button.card2:hover p.quantidade {
    opacity: 1;
  }

  button.card3:hover p.quantidade {
    opacity: 1;
  }

  button.card1:hover p.textbtn0 {
    margin-top: 20px;
    margin-right: 12px;
  }

  button.card2:hover p.textbtn1 {
    margin-top: 23px;
    margin-right: 16px;
  }

  button.card3:hover p.textbtn2 {
    margin-top: 20px;
    margin-right: 16px;
  }

  @media screen and (max-width: 1921px) {
    button.card1 {
      display: flex;
      width: 300px;
      height: 120px;
      background: linear-gradient(
          99.4deg,
          #04cb18 24.82%,
          rgba(0, 194, 255, 0) 114.94%
        ),
        #0bbe9e;
      border-radius: 29px;

      transition: 0.5s;
      border: none;
    }

    button.card1:hover {
      background: linear-gradient(
          99.4deg,
          #019a10 24.82%,
          rgba(0, 194, 255, 0) 114.94%
        ),
        #07584a;
      width: 320px;
      height: 140px;
    }

    button.card2 {
      display: flex;
      width: 300px;
      height: 120px;
      background: linear-gradient(
          99.57deg,
          rgba(36, 0, 255, 0) 10.43%,
          #e108e6 107.26%
        ),
        #fa00ff;
      border-radius: 29px;

      transition: 0.5s;
      border: none;
    }

    button.card2:hover {
      background: linear-gradient(
          99.57deg,
          rgba(34, 1, 238, 0) 10.43%,
          #ca09ce 107.26%
        ),
        #8c028e;
      width: 320px;
      height: 140px;
    }

    button.card3 {
      display: flex;
      width: 300px;
      height: 120px;
      background: linear-gradient(
          98.03deg,
          rgba(0, 6, 56, 0) 25.61%,
          #8ab0fa 101.8%
        ),
        #000aff;
      border-radius: 29px;

      transition: 0.5s;
      border: none;
    }

    button.card3:hover {
      background: linear-gradient(
          98.03deg,
          rgba(0, 6, 56, 0) 25.61%,
          #3479ff 101.8%
        ),
        #020544;
      width: 320px;
      height: 140px;
    }

    button p.textbtn0 {
      font-size: 30px;
      color: #ffffff;
      margin-top: 42px;
      margin-left: 45px;
      margin-right: 20px;
      transition: 0.2s;
    }

    button p.textbtn1 {
      font-size: 23px;
      color: #ffffff;
      margin-top: 45px;
      margin-left: 24px;
      transition: 0.2s;
    }

    button p.textbtn2 {
      font-size: 30px;
      color: #ffffff;
      margin-top: 42px;
      margin-left: 45px;
      transition: 0.2s;
    }

    button div i.fas {
      font-size: 54px;
      color: #ffffff;
      margin-top: 33px;
      margin-left: 24px;
      transition: 0.3s;
    }

    button div i.far {
      font-size: 54px;
      color: #ffffff;
      margin-top: 33px;
      margin-left: 30px;
      transition: 0.3s;
    }

    button.card1:hover div i.fas {
      font-size: 64px;
      margin-top: 38px;
      margin-left: 45px;
    }

    button.card2:hover div i.fas {
      font-size: 64px;
      margin-top: 38px;
      margin-left: 24px;
    }

    button.card3:hover div i.far {
      font-size: 64px;
      margin-top: 38px;
      margin-left: 30px;
    }

    button p.quantidade {
      font-size: 30px;
      color: #ffffff;
      margin-top: 10px;
      margin-left: 38px;
      transition: 0.3s;
      opacity: 0;
    }

    button.card1:hover p.textbtn0 {
      margin-top: 20px;
      margin-right: 12px;
    }

    button.card2:hover p.textbtn1 {
      margin-top: 23px;
      margin-right: 16px;
    }

    button.card3:hover p.textbtn2 {
      margin-top: 20px;
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 1450px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 12px;
    transition: 0.3s;
    justify-content: space-around;

    button.card1 {
      display: flex;
      width: 250px;
      height: 100px;
      background: linear-gradient(
          99.4deg,
          #04cb18 24.82%,
          rgba(0, 194, 255, 0) 114.94%
        ),
        #0bbe9e;
      border-radius: 29px;

      transition: 0.5s;
      border: none;
    }

    button.card1:hover {
      background: linear-gradient(
          99.4deg,
          #019a10 24.82%,
          rgba(0, 194, 255, 0) 114.94%
        ),
        #07584a;
      width: 320px;
      height: 120px;
    }

    button.card2 {
      display: flex;
      width: 250px;
      height: 100px;
      background: linear-gradient(
          99.57deg,
          rgba(36, 0, 255, 0) 10.43%,
          #e108e6 107.26%
        ),
        #fa00ff;
      border-radius: 29px;

      transition: 0.5s;
      border: none;
    }

    button.card2:hover {
      background: linear-gradient(
          99.57deg,
          rgba(34, 1, 238, 0) 10.43%,
          #ca09ce 107.26%
        ),
        #8c028e;
      width: 320px;
      height: 120px;
    }

    button.card3 {
      display: flex;
      width: 250px;
      height: 100px;
      background: linear-gradient(
          98.03deg,
          rgba(0, 6, 56, 0) 25.61%,
          #8ab0fa 101.8%
        ),
        #000aff;
      border-radius: 29px;

      transition: 0.5s;
      border: none;
    }

    button.card3:hover {
      background: linear-gradient(
          98.03deg,
          rgba(0, 6, 56, 0) 25.61%,
          #3479ff 101.8%
        ),
        #020544;
      width: 320px;
      height: 120px;
    }

    div.content-info {
      display: flex;
      flex-direction: column;
    }

    button p.textbtn0 {
      font-size: 24px;
      color: #ffffff;
      margin-top: 34px;
      margin-left: 40px;
      margin-right: 20px;
      transition: 0.2s;
    }

    button p.textbtn1 {
      font-size: 20px;
      color: #ffffff;
      margin-top: 37px;
      margin-left: 15px;
      transition: 0.2s;
    }

    button p.textbtn2 {
      font-size: 24px;
      color: #ffffff;
      margin-top: 34px;
      margin-left: 40px;
      transition: 0.2s;
    }

    button div i {
      display: flex;
      height: 100%;
    }

    button div i.fas {
      font-size: 54px;
      color: #ffffff;
      margin-top: 23px;
      margin-left: 12px;
      transition: 0.3s;
    }

    button.card1:hover div i.fas {
      font-size: 64px;
      margin-top: 30px;
      margin-left: 65px;
    }

    button.card2:hover div i.fas {
      font-size: 64px;
      margin-top: 30px;
      margin-left: 38px;
    }

    button div i.far {
      font-size: 54px;
      color: #ffffff;
      margin-top: 23px;
      margin-left: 20px;
      transition: 0.3s;
    }

    button.card3:hover div i.far {
      font-size: 64px;
      margin-top: 30px;
      margin-left: 50px;
    }

    button p.quantidade {
      font-size: 30px;
      color: #ffffff;
      margin-top: 10px;
      margin-left: 38px;
      transition: 0.3s;
      opacity: 0;
    }

    button.card1:hover p.quantidade {
      opacity: 1;
    }

    button.card2:hover p.quantidade {
      opacity: 1;
    }

    button.card3:hover p.quantidade {
      opacity: 1;
    }

    button.card1:hover p.textbtn0 {
      margin-top: 20px;
      margin-right: 12px;
    }

    button.card2:hover p.textbtn1 {
      margin-top: 23px;
      margin-right: 16px;
    }

    button.card3:hover p.textbtn2 {
      margin-top: 20px;
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 1209px) {
    display: flex;
    flex-direction: row;
    height: 140px;
    justify-content: space-around;
    align-items: center;
    transition: 0.3s;

    button.card3 {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 899px) {
    display: flex;
    flex-direction: row;
    width: 75%;
    height: 70px;
    justify-content: space-around;
    align-items: center;

    button.card1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 35px;
      background: linear-gradient(
          99.4deg,
          #04cb18 24.82%,
          rgba(0, 194, 255, 0) 114.94%
        ),
        #0bbe9e;
      border-radius: 29px;
      margin-bottom: 10px;
    }

    button.card1:hover {
      background: linear-gradient(
          99.4deg,
          #019a10 24.82%,
          rgba(0, 194, 255, 0) 114.94%
        ),
        #07584a;
      width: 120px;
      height: 40px;
    }

    button.card2 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 35px;
      background: linear-gradient(
          99.57deg,
          rgba(36, 0, 255, 0) 10.43%,
          #e108e6 107.26%
        ),
        #fa00ff;
      border-radius: 29px;
      margin-bottom: 10px;
    }

    button.card2:hover {
      background: linear-gradient(
          99.57deg,
          rgba(34, 1, 238, 0) 10.43%,
          #ca09ce 107.26%
        ),
        #8c028e;
      width: 120px;
      height: 40px;
    }

    button.card3 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 35px;
      background: linear-gradient(
          98.03deg,
          rgba(0, 6, 56, 0) 25.61%,
          #8ab0fa 101.8%
        ),
        #000aff;
      border-radius: 29px;
      margin-bottom: 10px;
    }

    button.card3:hover {
      background: linear-gradient(
          98.03deg,
          rgba(0, 6, 56, 0) 25.61%,
          #3479ff 101.8%
        ),
        #020544;
      width: 120px;
      height: 40px;
    }

    div.content-info {
    }

    button div i {
      display: flex;
      height: 0%;
    }

    button p.textbtn0 {
      font-size: 13px;
      color: #fff;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }

    button p.textbtn1 {
      font-size: 12px;
      color: #fff;
      margin-top: 0px;
      margin-left: 0px;
    }

    button p.textbtn2 {
      font-size: 13px;
      color: #fff;
      margin-top: 0px;
      margin-left: 0px;
    }

    button p.quantidade {
      font-size: 0px;
      margin-top: 0px;
      margin-left: 0px;
    }

    button div i.fas {
      font-size: 0px;
      margin-top: 0px;
      margin-left: 0px;
    }

    button div i.far {
      font-size: 0px;
      margin-top: 0px;
      margin-left: 0px;
    }

    button div i.far {
      font-size: 0px;
    }

    button.card1:hover p.textbtn0 {
      margin-top: 0px;
      margin-right: 0px;
    }

    button.card2:hover p.textbtn1 {
      margin-top: 0px;
      margin-right: 0px;
    }

    button.card3:hover p.textbtn2 {
      margin-top: 0px;
      margin-right: 0px;
    }

    button.card3:hover div i.far {
      font-size: 0px;
      margin-top: 0px;
      margin-left: 0px;
    }

    button.card2:hover div i.fas {
      font-size: 0px;
      margin-top: 0px;
      margin-left: 0px;
    }

    button.card1:hover div i.fas {
      font-size: 0px;
      margin-top: 0px;
      margin-left: 0px;
    }
  }

  .menu-lateral {
    position: fixed;
    display: flex;
    left: 0;
    top: 4%;
    width: 100px;
    background: #475e70;
    //background: ${(props) => props.backgroundColor};
    flex-direction: column;
    height: 96%;
    padding: 15px 10px;
    justify-content: flex-end;
    transition: 0.2s;
    z-index: 10;
    max-width: 240px;
  }

  .button-class {
    width: 95%;
  }

  .button-class button {
    width: 95%;
    margin-left: 2.2%;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 10px;
    border: 0;
    transition: 0.3s;
    text-align: inherit;
    white-space: nowrap;
    padding: 0;
    height: 30px;
  }

  .button-1 button {
    //background-color: #b31fff;
    background-color: #5e5eff00;
    color: white;
  }

  .button-2 button {
    //background-color: #019a10;
    background-color: #5e5eff00;
    color: white;
  }

  .button-3 button {
    //background-color: #5e5eff;
    background-color: #5e5eff00;
    color: white;
  }

  .button-class button:hover {
    box-shadow: 0px 0px 13px 3px #00000045;
    transition: 0.3s;
  }
`;

export const Label = styled.label`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: bold;
  color: ${(props) => props.fontColor};

  @media only screen and (max-width: 1366px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 394px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 320px) {
    font-size: 12px;
  }
`;

export const OptionsList = styled.div`
  position: fixed;
  max-width: 240px;
  min-width: 195px;
  padding: 16px;
  height: 96vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border-right: #dadada 1px;
  box-shadow: 0 0.2px 4px rgb(0 0 0 / 10%);

  @media only screen and (max-width: 767px) {
    height: calc(100vh - 75px);
    justify-content: flex-start;
  }

  div.option {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
  }

  div.option:hover {
    opacity: 0.8;
  }

  div.option i {
    font-size: 16px;
    margin-left: 4px;
    color: ${(props) => props.brandColor};
  }

  div.option h3 {
    margin-left: 8px;
    font-size: 13px;
    font-weight: 500;
    color: ${(props) => props.fontColor};
  }

  div.sub-option {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    padding-left: 2.5rem;
  }

  div.sub-option:hover {
    opacity: 0.8;
  }

  div.sub-option h4 {
    font-size: 11px;
    font-weight: 500;
    color: ${(props) => props.fontColor};
  }
`;

export const TableMaterials = styled.div`
  height: 100%;

  .table-class {
    height: 93%;
    overflow: auto;
    width: 95%;
  }

  .painel-class {
    height: 740px;
    width: 100%;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td {
    padding: 5px;
  }

  thead tr {
    font-weight: 500;
  }

  tr {
    height: 40px;
  }

  tbody tr:nth-child(odd) {
    background-color: #e3e3e3;
  }

  .LastAccess {
    text-align: end;
  }

  tbody .Quantidade {
    font-size: 18px;
  }

  tbody tr:hover {
    background-color: #cfcfcf;
  }
`;
export const TableAcess = styled.div`
  height: 100%;

  .table-class {
    height: 87%;
    overflow: auto;
    width: 95%;
  }

  .painel-class {
    height: 394px;
    width: 100%;
    background-color: #383838;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td {
    padding: 5px;
  }

  thead tr {
    font-weight: 500;
  }

  tr {
    height: 40px;
    color: white;
  }

  tbody tr:nth-child(odd) {
    background-color: #4c4c4c;
  }

  .LastAccess {
    text-align: center;
  }

  tbody .Quantidade {
    font-size: 18px;
    text-align: center;
  }

  thead .Quantidade,
  thead .LastAccessPeriod,
  tbody .LastAccessPeriod {
    text-align: center;
  }

  tbody tr:hover {
    background-color: #5e5e5e;
  }
`;
