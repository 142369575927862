import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 67.5vw;
  width: 90vw;
  border-radius: 8px;
  background-color: transparent;
  margin: 0 .2rem 1.4rem .2rem;
  cursor: pointer;

  @media only screen and (min-width: 1024px) {
    width: 21.5vw;
    height: 16.125vw;
    margin: 0 .5vw 1.4rem .5vw;

    div.active {
      z-index: 5 !important;
    }

    img {
      width: 21.5vw !important;
      height: 16.125vw !important;
    }
  }

  @media only screen and (min-height: 1750px) and (max-width: 1080px) {  

    width: auto;
    height: auto;
    margin: none;

    img {
      height: auto!important;
      width: 420px!important;
    }

  }

  img {
    position: relative;
    border-radius: 8px;
    height: 67.5vw;
    width: 90vw;
    object-fit: cover;
  }

  div.card-shadow {
    height: 70%;
    width: 100%;
    position: absolute;
    border-radius: 8px;
    background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.9));
    bottom: 0;
  }

  h4 {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: #e3e3e3;
    margin: .6rem .8rem .6rem .8rem;
    bottom: 0;
  }

  .active {
    z-index: 4;
  }
`