import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex: 1;
  flex-wrap: no-wrap;
  height: 38px;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  z-index: 1;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.1);

  @media only screen and (min-width: 768px) {
    h3 {
      margin-left: 24px !important;
    }

    img {
      right: 24px !important;
    }
  }

  @media only screen and (min-height: 1750px) and (max-width: 1080px) {
    height: 48px;

    img {
      height: 32px !important;
    }

    .logoutBtn {
      right: 16px;
      font-size: 16px;
    }

  }

  .logoutBtn {
    background-color: red;
    position: absolute;
    right: 8px;
    color: #f0f0f0;

    i {
      position: relative;
      left: 0;
      color: #f0f0f0;

      :hover {
        color: #f0f0f0;
        opacity: 1;
      }
    }

    :hover {
      opacity: 0.7;
    }
  }

  .logoutModal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    top: 0;
    background-color: #00000057;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;

    .contentLogout {
      background-color: white;
      width: 300px;
      border-radius: 8px;
      padding: 16px;
      position: relative;
    }

    p {
      padding: 8px 0 16px;
      text-align: center;
      font-size: 14px;
    }

    .buttons {
      display: flex;
      gap: 8px;
      button {
        border-radius: 8px;
        width: 50%;
        height: 30px;
        :first-child {
          background-color: transparent;
          border: 1px solid #292929;
          color: #292929;
        }

        :last-child {
          background-color: red;
        }
      }
    }
  }

  i {
    font-size: 16px;
    color: ${(props) => props.brandColor};
    left: 16px;
    position: absolute;
  }

  i:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  img {
    height: 24px;
  }

  .p-greeting {
    left: 12px;
    position: absolute;
    margin: 0px;
  }

  .p-greeting strong {
    font-weight: 500;
  }

  button {
    margin-left: 5px;
    border-radius: 30px;
    padding: 5px 18px;
    border: 0;
    background: limegreen;
    color: white;
  }

  .testWarning {
    margin-left: 5px;
    border-radius: 30px;
    padding: 5px 18px;
    border: 0;
    background: #119956;
    color: white;
  }

  .testOver {
    margin-left: 5px;
    border-radius: 30px;
    padding: 5px 18px;
    border: 0;
    background: gray;
    color: white;
  }

  .modalTest {
    text-align: center;
    padding: 51px;
    width: 35%;
    border-radius: 10px;
    position: absolute;
    top: 326px;
    background: white;
    z-index: 2;
  }

  .fundo {
    z-index: 1;
    top: 0;
    height: 100%;
    position: fixed;
    width: 100%;
    background: #3a3a3a6e;
  }

  .modalTest h1 {
    margin-bottom: 10px;
  }
`;
