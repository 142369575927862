import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// Styles
import { Container, Text } from './styles'

const MaterialCard = (props) => {
  
  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
  const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

  return (
    <>
      <Container fontColor={fontColor}>
          <img className="thumb" src={props.material.miniThumbPath ? props.material.miniThumbPath : props.material.thumbPath}></img>
          <div className="material-data">
            <h3>{props.material.description}</h3>            
            <h4>{props.material.brand} - {props.material.format}</h4>
            <h4>{props.material.surface.description}</h4>
          </div>
      </Container>
    </>
  )
}

export default MaterialCard