class Playcanvas {
  
  constructor() {
    this.iframeContent = null;
    this.iframeReady = false;
    this.messagesQueue = [];
  }

  connectToIframe() {
    // @ts-ignore
    this.iframeContent = document.getElementById("game-iframe").contentWindow;
  }

  sendMessage(type, data) {

    this.iframeContent.postMessage(
      {
        type: type,
        data: data
      },
      "*"
    );
  }
}

export default Playcanvas;