import React, { useEffect, useState, useRef } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Switch from "react-switch";
import Dropdown from 'react-dropdown'
import { DataGrid } from '@mui/x-data-grid'
import { useDispatch } from 'react-redux'
import * as roomsActions from "../../../../../store/modules/rooms/actions"
import GenericModal from '../../../../../components/GenericModal';
import { Container, Butoon } from "./styles";

import api from "../../../../../services/api";

const SingleRoom = (props) => {
    const [status, setStatus] = useState(true);
    const [playCanvas, setPlayCanvas] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [thumbPath, setThumbPath] = useState("");
    const [type, setType] = useState("");
    const [cameras, setCameras] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [addCamera, setAddCamera] = useState(false);
    const [thumbChanged, setThumbChanged] = useState(false);
    const [fakeId, setFakeId] = useState(0);

    const [successModalState, setSuccessModalState] = useState(false);

    const { room, setRooms } = props;

    const inputFile = useRef(null);


    const [currentCamera, setCurrentCamera] = useState({
        id: cameras.length,
        sequence: "",
        position: "",
        rotation: "",
        FOV: "",
        mobilePosition: "",
        mobileRotation: "",
        mobileFOV: "",
        thumbPath: "",
    });

    const options = [
        "Fachada",
        "Banheiro",
        "Quarto",
        "Cozinha",
        "Área Gourmet",
        "Lavanderia",
        "Escritório",
        "Sala",
        "Apartamento",
        "Closet",
    ];

    const columns = [
        { field: "sequence", headerName: "Sequência", width: 160 },
        { field: "position", headerName: "Posição", width: 160 },
        { field: "rotation", headerName: "Rotação", width: 160 },
        { field: "FOV", headerName: "FOV", width: 160 },
        { field: "mobilePosition", headerName: "Posição Mobile", width: 180 },
        { field: "mobileRotation", headerName: "Rotação Mobile", width: 180 },
        { field: "mobileFOV", headerName: "Mobile FOV", width: 180 },
        { field: "thumbPath", headerName: "Thumb", width: 160 },
        {
            field: "Editar",
            renderCell: (cellValues) => {
                return (
                    <Butoon
                        type="button"
                        onClick={(event) => {
                            setCurrentCamera(cellValues.row);
                            setAddCamera(true);
                        }}
                    >
                        Editar
                    </Butoon>
                );
            },
            width: 130,
        },
        {
            field: "Excluir",
            renderCell: (cellValues) => {
                return (
                    <Butoon
                        type="button"
                        onClick={(event) => {
                            setTimeout(() => {
                                setCameras(
                                    cameras.filter((camera) => {
                                        return (
                                            camera.sequence !==
                                            cellValues.row.sequence
                                        );
                                    })
                                );
                            });
                        }}
                    >
                        Excluir
                    </Butoon>
                );
            },
            width: 130,
        },
    ];

    const handleTypeChange = (type) => {
        setType(type.value);
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const handleFileSelect = (file) => {
        setThumbPath(URL.createObjectURL(file));
        setThumbChanged(true);
    };

    const blobToBase64 = (blobUrl) => {
        return new Promise((resolve, _) => {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "blob";

            xhr.onload = function () {
                var recoveredBlob = xhr.response;

                var reader = new FileReader();

                reader.onloadend = () => resolve(reader.result);

                reader.readAsDataURL(recoveredBlob);
            };

            xhr.open("GET", blobUrl);
            xhr.send();
        });
    };

    const uploadThumb = async (fileName) => {
        let thumbHelper = await blobToBase64(thumbPath);

        thumbHelper = thumbHelper
            .replace("data:image/webpbase64,", "")
            .replace("data:image/webp;base64,", "")
            .replace("data:image/pngbase64,", "")
            .replace("data:image/png;base64,", "")
            .replace("data:image/jpgbase64,", "")
            .replace("data:image/jpg;base64,", "")
            .replace("data:image/jpegbase64,", "")
            .replace("data:image/jpeg;base64,", "");

        return api.post("order/uploadFile", {
            key: `assets/img/ambientes/${type.value}/${fileName}.png`,
            data: thumbHelper,
        });
    };

    const handleSubmitRoom = async () => {
        let newRoom = { playCanvas, title, description, thumbPath };

        newRoom.type = {
            description: type,
        };

        let newCameras = cameras.map((c) => {
            return {
                sequence: c.sequence,
                position: c.position,
                rotation: c.rotation,
                FOV: c.FOV,
                mobilePosition: c.mobilePosition,
                mobileRotation: c.mobileRotation,
                mobileFOV: c.mobileFOV,
                thumbPath: c.thumbPath,
            };
        });

        newRoom.cameras = newCameras;

        if (thumbChanged) {
            const uploadResp = await uploadThumb(`${title}-${Date.now()}`);

            setThumbChanged(false);

            newRoom.thumbPath = uploadResp.data.data.imageSrc;

            if (!uploadResp.data.success) {
                console.log(
                    `Erro ao fazer upload da thumbnail. Mensagem de erro: ${uploadResp.data.status}`
                );
                return;
            }
        }

        let resp;

        if (isEditing) {            
            resp = await api.put(`room/${room._id}`, newRoom);
        } else resp = await api.post("room", newRoom);

        if (resp.data.success) {
            api.get("/room").then((res) => {
                const roomsArray = res.data.rooms;

                setRooms(roomsArray);
            });
            setSuccessModalState(true);
        }
    };

    useEffect(() => {
        if (room) {
            if (room.status === "active") {
                setStatus(true);
            } else {
                setStatus(false);
            }

            setPlayCanvas(room.playCanvas);
            setTitle(room.title);
            setDescription(room.description);
            setThumbPath(room.thumbPath);
            setType(room.type.description);

            room.cameras.forEach((camera) => {
                camera.id = camera._id;
            });

            setCameras(room.cameras);
            setIsEditing(true);
        }
    }, [room]);

    return (
        <>
            <Container>
                <div className="room-details">
                    <FormControl className="new-room-form-item">
                        <label>Título</label>
                        <input
                            type="text"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                            required
                        />
                    </FormControl>

                    <FormControl className="new-room-form-item">
                        <label>Descrição</label>
                        <input
                            type="text"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            required
                        />
                    </FormControl>

                    <FormControl className="new-room-form-item">
                        <label>PlayCanvas</label>
                        <input
                            type="text"
                            onChange={(e) => setPlayCanvas(e.target.value)}
                            value={playCanvas}
                            required
                        />
                    </FormControl>

                    <FormControl>
                        <label>Ativo</label>
                        <Switch
                            onChange={(e) => setStatus(!status)}
                            checked={status}
                        />
                    </FormControl>

                    <FormControl className="new-room-form-item thumb-path-input">
                        <label>Thumb</label>
                        <img alt="" className="thumb-img" src={thumbPath} />
                        <button
                            type="file"
                            className="thumb-btn"
                            onClick={onButtonClick}
                        >
                            Selecionar
                        </button>
                        <input
                            type="file"
                            id="file"
                            ref={inputFile}
                            accept="image/*"
                            onChange={(e) =>
                                handleFileSelect(e.target.files[0])
                            }
                            style={{ display: "none" }}
                        />
                    </FormControl>

                    <div
                        className={
                            thumbPath
                                ? "new-room-form-item "
                                : "new-room-form-item new-room-form-item-with-no-image"
                        }
                    >
                        <label>Tipo</label>
                        <Dropdown
                            className="dropContratos"
                            options={options}
                            onChange={handleTypeChange}
                            value={type}
                            placeholder="Selecione o tipo"
                        />
                    </div>
                </div>

                <div className="cameras-header">
                    <h3>Cameras</h3>
                    {!addCamera && (
                        <button
                            className="btn-novo-ambiente"
                            onClick={(e) => {
                                let newId = fakeId + 1;
                                setFakeId(newId);
                                setAddCamera(true);
                            }}
                        >
                            Adicionar câmera
                        </button>
                    )}
                </div>

                {(addCamera === false && (
                    <div className="camera-list">
                        <DataGrid
                            rows={cameras}
                            rowCount={false}
                            columns={columns}
                            pageSize={30}
                            rowsPerPageOptions={[30]}
                            hideFooterSelectedRowCount
                        />
                    </div>
                )) ||
                    (addCamera && (
                        <div className="cameras-container">
                            <FormControl>
                                <label>Sequência</label>
                                <input
                                    type="text"
                                    value={currentCamera.sequence}
                                    onChange={(e) =>
                                        setCurrentCamera({
                                            ...currentCamera,
                                            sequence: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <label>Posição</label>
                                <input
                                    type="text"
                                    value={currentCamera.position}
                                    onChange={(e) =>
                                        setCurrentCamera({
                                            ...currentCamera,
                                            position: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <label>Rotação</label>
                                <input
                                    type="text"
                                    value={currentCamera.rotation}
                                    onChange={(e) =>
                                        setCurrentCamera({
                                            ...currentCamera,
                                            rotation: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <label>FOV</label>
                                <input
                                    type="text"
                                    value={currentCamera.FOV}
                                    onChange={(e) =>
                                        setCurrentCamera({
                                            ...currentCamera,
                                            FOV: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <label>Posição Mobile</label>
                                <input
                                    type="text"
                                    value={currentCamera.mobilePosition}
                                    onChange={(e) =>
                                        setCurrentCamera({
                                            ...currentCamera,
                                            mobilePosition: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <label>Rotação Mobile</label>
                                <input
                                    type="text"
                                    value={currentCamera.mobileRotation}
                                    onChange={(e) =>
                                        setCurrentCamera({
                                            ...currentCamera,
                                            mobileRotation: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <label>FOV Mobile</label>
                                <input
                                    type="text"
                                    value={currentCamera.mobileFOV}
                                    onChange={(e) =>
                                        setCurrentCamera({
                                            ...currentCamera,
                                            mobileFOV: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <label>Thumb</label>
                                <input
                                    type="text"
                                    value={currentCamera.thumbPath}
                                    onChange={(e) =>
                                        setCurrentCamera({
                                            ...currentCamera,
                                            thumbPath: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </FormControl>

                            <FormControl className="buttons-camera">
                                <button
                                    className="btn-voltar-camera"
                                    onClick={(e) => setAddCamera(false)}
                                >
                                    Voltar
                                </button>

                                <button
                                    className="btn-novo-ambiente"
                                    onClick={(e) => {
                                        if (currentCamera.id == 0) {
                                            setCurrentCamera({
                                                ...currentCamera,
                                                id: fakeId,
                                            }); //
                                        }

                                        cameras.forEach((camera) => {
                                            if (
                                                currentCamera.id === camera.id
                                            ) {
                                                camera.sequence =
                                                    currentCamera.sequence;
                                                camera.position =
                                                    currentCamera.position;
                                                camera.rotation =
                                                    currentCamera.rotation;
                                                camera.FOV = currentCamera.FOV;
                                                camera.mobilePosition =
                                                    currentCamera.mobilePosition;
                                                camera.mobileRotation =
                                                    currentCamera.mobileRotation;
                                                camera.mobileFOV =
                                                    currentCamera.mobileFOV;
                                            }
                                        });
                                        if (
                                            cameras.some((camera) => {
                                                return (
                                                    currentCamera.id ==
                                                    camera.id
                                                );
                                            })
                                        ) {
                                            const newCameras = cameras.map(
                                                (camera) => {
                                                    if (
                                                        currentCamera.id ===
                                                        camera.id
                                                    ) {
                                                        camera.sequence =
                                                            currentCamera.sequence;
                                                        camera.position =
                                                            currentCamera.position;
                                                        camera.rotation =
                                                            currentCamera.rotation;
                                                        camera.FOV =
                                                            currentCamera.FOV;
                                                        camera.mobilePosition =
                                                            currentCamera.mobilePosition;
                                                        camera.mobileRotation =
                                                            currentCamera.mobileRotation;
                                                        camera.mobileFOV =
                                                            currentCamera.mobileFOV;
                                                    }
                                                    return camera;
                                                }
                                            );
                                            setCameras(newCameras);
                                            setCurrentCamera({
                                                id: fakeId,
                                                sequence: "",
                                                position: "",
                                                rotation: "",
                                                FOV: "",
                                                mobilePosition: "",
                                                mobileRotation: "",
                                                mobileFOV: "",
                                                thumbPath: "",
                                            });

                                            setAddCamera(false);
                                        } else {
                                            setCameras([
                                                ...cameras,
                                                currentCamera,
                                            ]);
                                            setCurrentCamera({
                                                id: fakeId,
                                                sequence: "",
                                                position: "",
                                                rotation: "",
                                                FOV: "",
                                                mobilePosition: "",
                                                mobileRotation: "",
                                                mobileFOV: "",
                                                thumbPath: "",
                                            });

                                            setAddCamera(false);
                                        }
                                    }}
                                >
                                    Salvar câmera
                                </button>
                            </FormControl>
                        </div>
                    ))}

                <button
                    className="btn-novo-ambiente end"
                    onClick={handleSubmitRoom}
                >
                    Salvar ambiente
                </button>
            </Container>

            <GenericModal
                display={successModalState}
                onCloseClick={() => setSuccessModalState(false)}
                arrowYPosition={"28vh"}
                arrowXPosition={"6vw"}
                mainContent={
                    <>
                        <div className="success-modal">
                            <h3>Salvar ambiente</h3>
                            <p>Ambiente salvo com sucesso.</p>
                            <button
                                className="btn-novo-ambiente"
                                onClick={(e) => setSuccessModalState(false)}
                            >
                                Fechar
                            </button>
                        </div>
                    </>
                }
            ></GenericModal>
        </>
    );
};

export default SingleRoom