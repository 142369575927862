import styled from 'styled-components'

export const Container = styled.div`
/*display: flex;
flow-flex: row wrap;
justify-content: space-around;
align-items: flex-start;
align-content: flex-start;*/
gap: 12px;
/* width: 100%;
height: 100%; */s
padding: 24px;
background-color: ${props => props.backgroundColor};

.dados-cadastrais-items {
  margin-left: 24px;
}

.cnpj-item {
  margin-left:0;
}

.nome-item {
  width: 380px;
}

.responsavel-item {
  width: 320px;  
  margin-left:0;
}

.email-item {
  width: 340px;  
}

.associacao-item {
  width: 180px;  
}

.valor-mensalidade-item {
  width: 140px;  
  margin-left:0;
}

.valor-mensalidade-item input {
  text-align: end;
}

.dia-faturamento-item input {
  text-align: center;
}

.dia-faturamento-item {
  width: 140px;  
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

`

export const ButtonGroup  = styled.div`

`

export const Button  = styled.div`



`