import styled from 'styled-components'

export const Container = styled.div`
  padding: 24px;
  border: 1px #e4e4e4 solid;
  height: calc(100% - 48px);
  border-radius: 15px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 5fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

label {
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 3px;
  margin-left: 4px;
  font-family: 'Montserrat',sans-serif;
  color: #222;
  font-weight: bold;
}

.form-user {
  grid-area: 1 / 1 / 2 / 2;
}

.error-msg {
  grid-area: 1 / 2 / 2 / 3;
  align-self: self-end;
  padding: 8px;
}

.footer-user {
  grid-area: 2 / 1 / 3 / 3;
  align-self: self-end;  
}

.error-validate {
  color: red;
  width: 100%;
  float: left;  
  margin: 2px;
}

.save-msg-label {
  color: green;
  text-align: center;
  float: inline-start;
  left: 50%; 
  display: block;
  position: absolute;
}

.user-data-items-left {
  margin-left: 24px;
}

.cancel-user-btn {
  padding: 6px 18px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 20%);
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  color: #2196F3;
  background-color: transparent;  
  float: right;
}

.save-user-btn {
  padding: 6px 28px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 20%);
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  color: #fff;
  background-color: #2196F3;  
  margin-left: 12px;
  float: right;
}

.save-user-btn :hover,
.cancel-user-btn :hover {
  opacity: .7;
}

`

export const ButtonGroup  = styled.div`

`

export const Button  = styled.div`

`