import styled from "styled-components";

export const Container = styled.div`
  width: 94vw;
  margin: 0 3vw auto 3vw;
  height: ${(props) => props.innerHeight - 204}px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;


  @media only screen and (min-width: 1024px) {
    width: 372px !important;
    margin: 0 0 auto 8px !important;
    height: calc(100vh - 138px);

    div.divider {
      width: 364px !important;
    }

    li,
    .slide {
      margin: 0 4px 0.2rem 4px !important;
    }

    li img {
      width: calc(260px / 3) !important;
    }

    div.card-shadow {
      width: calc(260px / 3) !important;
    }

    .active {
      z-index: 5 !important;
    }
  }

  @media only screen and (min-height: 1750px) and (max-width: 1080px) {
    width: 100%!important;

    button.button-next {
      display: none!important;
    }

  }

  div.space {
    height: 70px;
    width: 100%;
  }

  div.divider {
    position: relative;
    flex-wrap: wrap;
    width: 94vw;
    margin-top: 8px;
  }

  div h2 {
    color: ${(props) => props.fontColor};
    font-size: 15px;
    margin: 0 0 10px 8px;
    font-weight: 700;
    text-align: start;
    width: 100%;
  }

  ul,
  .slider {
    display: flex;
    height: auto;
    overflow: auto;
    margin-bottom: 32px;
    padding-right: 20px;
    transition: transform 0.3s linear;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  li,
  .slide {
    margin: 0 2vw 0.2rem 2vw;
    border-radius: 8px;
    height: auto;
    cursor: pointer;
    position: relative !important;
  }

  li img {
    width: calc(88vw / 3);
    height: auto;
    border-radius: 8px;
  }

  button.button-back,
  button.button-next {
    position: absolute;
    top: 50%;
    margin-top: -14px;
    height: 28px;
    width: 28px;
    border-radius: 14px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: right;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #005393;
    background-color: #f5f5f5;
  }

  button.button-back:disabled,
  button.button-next:disabled {
    display: none;
  }

  button.button-back:hover,
  button.button-next:hover {
    opacity: 0.8;
  }

  button.button-back {
    left: 0;
  }

  button.button-next {
    right: 8px;
  }

  .table__nav {
    align-items: center;
    display: flex;
  }

  .table__nav__title {
    color: #141414;
    font-size: 14px;
    margin: 0 0 0.4rem 0.5rem;
    font-weight: 500;
  }

  .list {
    display: flex;
    width: 100%;
    height: auto;
    overflow: auto;
    margin-bottom: 1rem;
  }

  li {
    position: relative;
    margin: 0 0.2rem 0.4rem 0.2rem;
    border-radius: 8px;
    height: auto;
    cursor: pointer;
  }

  li:hover {
    opacity: 0.8;
  }

  .active {
    z-index: 4;
  }

  .list__item__img {
    width: auto;
    height: 88px;
    border-radius: 8px;
  }

  div.card-shadow {
    height: 100%;
    width: calc(88vw / 3);
    position: absolute;
    border-radius: 8px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    bottom: 0;
  }

  .list__item__title {
    font-size: 11px;
    color: #141414;
    margin: 0.2rem 0.8rem 0.6rem 0.8rem;
    position: absolute;
    font-weight: 400;
    color: #fff;
    bottom: 0;
  }
`;
