import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

import * as loginActions from "./../../store/modules/login/actions";
import * as userRoomsActions from "./../../store/modules/userRooms/actions";
import * as contractActions from "./../../store/modules/contract/actions";

// Styles
import { Container, Header } from "./styles";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const userId = useSelector((state) => state.loginReducer.user.id);
  const contractId = useSelector((state) => state.contractReducer.contractId);

  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const isMyRoomsLoading = useSelector(
    (state) => state.userRoomsReducer.isLoading
  );
  const myRooms = useSelector((state) => state.userRoomsReducer.rooms);

  const brandContrastColor = useSelector(
    (state) => state.contractReducer.contract.config.brandContrastColor
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );
  const alertColor = useSelector(
    (state) => state.contractReducer.contract.config.alertColor
  );
  const logoPath = useSelector(
    (state) => state.contractReducer.contract.config.logoPath
  );

  useEffect(() => {
    isLoggedIn &&
      !isMyRoomsLoading &&
      (myRooms && myRooms != []
        ? history.push("/")
        : history.push("/novo-ambiente"));
    isLoggedIn &&
      isMyRoomsLoading &&
      dispatch(
        userRoomsActions.getUserRooms({ user: userId, contract: contractId })
      );
  }, [isLoggedIn, isMyRoomsLoading]);

  const isValidPassword = (password, confirmPassword) => {
    return password.length > 3 && password == confirmPassword;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidPassword(password, confirmpassword)) {
      api
        .post("reset/" + new URL(document.location).searchParams.get("token"), {
          password: password,
        })
        .then((response) => {
          //console.log(response);
          // dispatch();
          // loginActions.loginSubmit({
          //     login: response.data.email,
          //     password: password,
          //     setErrorMessage,
          // })\

          api
            .post("signin/", {
              login: response.data.email,
              password: password,
            })
            .then(({ data }) => {
              if (data?.token) {
                //salva dados
                dispatch(loginActions.saveUserData(data));

                if (data.token) {
                  localStorage.setItem("@CRIA_TOKEN", data.token);
                  sessionStorage.setItem("@CRIA_TOKEN", data.token);
                }

                dispatch(contractActions.getContract(data.user.id));

                getOrders(data.user.id);
              }
            });
        })
        .catch((error) => console.log(error));
    } else {
      if (password.length < 3) {
        setErrorMessage("A senha deve conter 4 ou mais caracteres.");
      } else {
        setErrorMessage("As senhas não são iguais. Tente novamente.");
      }
    }
  };

  const getOrders = (id) => {
    api.get(`/order?user=${id}`).then(({ data: dataContract }) => {
      const hasOrder = dataContract.orders.find(
        (element) => element.status !== "deleted"
      );

      hasOrder ? history.push("/") : history.push("/novo-ambiente");
    });
  };

  return (
    <Container
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      brandColor={brandColor}
      brandContrastColor={brandContrastColor}
      alertColor={alertColor}
    >
      <Header>
        <img src={logoPath} alt="Logotipo"></img>
      </Header>
      <h2>Redefinição de senha</h2>
      <h4>
        Para redefinir sua senha, basta completar
        <br />
        os campos abaixo e "Redefinir senha".
      </h4>
      <h5>{errorMessage}</h5>
      <div className="input-container">
        <input
          type="password"
          placeholder="Nova senha"
          onChange={(event) => setPassword(event.target.value)}
        />
        <i className="fas fa-envelope"></i>
      </div>
      <div className="input-container">
        <input
          type="password"
          placeholder="Confirme sua nova senha"
          onChange={(event) => setConfirmPassword(event.target.value)}
        />
        <i className="fas fa-lock"></i>
      </div>
      <button className="submit-button" onClick={handleSubmit}>
        Redefinir senha
      </button>
    </Container>
  );
};

export default NewPassword;
