import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import InputMask from "react-input-mask";

import { Container } from "./styles";

import { saveAs } from "file-saver";

import api from "../../../../services/api";

import ModalBackground from "../../../../components/ModalBackground";

import GenericModal from "../../../../components/GenericModal";

import ReactGA from "react-ga";

import CircularProgress from "@material-ui/core/CircularProgress";

const ScreenshotModal = (props) => {
  const brandContrastColor = useSelector(
    (state) => state.contractReducer.contract.config.brandContrastColor
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const { whatsappMessageSent, setWhatsappMessageSent } = props;
  const contract = useSelector((state) => state.contractReducer.contract);

  let userPhone = useSelector((state) => state.loginReducer.user.phone);

  const userId = useSelector((state) => state.loginReducer.user.id);
  const userName = useSelector((state) => state.loginReducer.user.name);

  const [inputMask, setInputMask] = useState("55 99 9 9999 9999");
  const [isContactNumberValid, setIsContactNumberValid] = useState(true);
  const [isNamePersonValid, setIsNamePersonValid] = useState(true);

  const [nameInput, setNameInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");

  const [displayContactForm, setDisplayContactForm] = useState(false);

  const [displayWarningModel, setDisplayWarningModel] = useState(false);

  // const [displayConfirmation, setDisplayConfirmation] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  let [loadingWpp, setLoadingWpp] = useState(false);

  // let [userPhoneWhatsapp, setUserPhoneWhatsapp] = useState(
  //   userPhone ? true : false
  // );

  let [whatsapp, setWhatsapp] = useState(userPhone ? true : false);
  const [phoneWppValue, setPhoneWppValue] = useState();

  const refButton = useRef(null);

  useEffect(() => {
    veryfyPhone();

    function onKeyup(e) {
      if (e.key === "Enter") {
        refButton.current.click();
      }
    }
    window.addEventListener("keyup", onKeyup);
    return () => window.removeEventListener("keyup", onKeyup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveButtonClick = () => {
    saveAs(props.imgSrc, "CRIA - Ambiente.jpg");
  };

  useEffect(() => {
    phoneInput.length >= 20
      ? setInputMask("+55 (99) 9 9999-9999")
      : setInputMask("+55 (99) 9 9999-9999");
  }, [phoneInput]);

  const validateName = () => {
    if (nameInput === "" || !nameInput) {
      setIsNamePersonValid(false);
      return false;
    }
    return true;
  };

  const validateContactNumber = () => {
    if (phoneInput.length !== 20) {
      setIsContactNumberValid(false);
      return false;
    }
    return true;
  };

  const addWhatsappMessage = () => {
    api
      .post("/user/" + userId + "/whatsappMessagesSent", {
        whatsappMessagesSent: {
          dateMessage: Date(),
        },
      })
      .catch((err) => console.log(err));
  };

  const sendWhatsappMessage = async (phone, name, imgSrc, loading) => {
    //setShowLoading(true)

    const phoneNoMask = phone
      .replace(" ", "")
      .replace("+", "")
      .replace("(", "")
      .replace(")", "")
      .replace("-", "");
    // const phoneUserNoMask = userPhone
    //   .replace(" ", "")
    //   .replace("+", "")
    //   .replace("(", "")
    //   .replace(")", "")
    //   .replace("-", "");
    //loading = setShowLoading(true);
    if (!validateName() || !validateContactNumber()) return false;

    // const msg = `Hey ${name}, este é o ambiente que montamos para você. Gostou?

    //     Qualquer dúvida é só me chamar clicando no -> https://wa.me/${phoneUserNoMask}`;

    setShowLoading(true);

    addWhatsappMessage();

    const extension = "jpg";
    const typeImage = "image/jpeg";

    const urlSaveImage = await api.post("order/uploadFile", {
      key: `orders/${userId}-${Date.now()}.${extension}`,
      data: imgSrc,
      contentType: typeImage,
    });

    api
      .post("global/sendwhatsapp", {
        phone: phoneNoMask,
        imageUrl: urlSaveImage.data.data.imageSrc,
        customerName: name,
      })
      .then(({ data }) => {
        setWhatsappMessageSent(true);
        setTimeout(() => {
          setDisplayContactForm(false);
          setTimeout(() => {
            setWhatsappMessageSent(false);
          }, 5000);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setShowLoading(false);
      });

    ReactGA.event({
      category: `${contract.name} - (criadecor)`,
      label: userName,
      action: "Whatsapp enviado",
    });
  };

  const veryfyPhone = () => {
    api
      .get(`user/${userId}`)
      .then((data) => {
        if (data.data.user.phone) {
          userPhone = data.data.user.phone;
          setWhatsapp(true);
        }
      })
      .catch((error) => console.log(error));
  };

  const setPhoneWppF = () => {
    api
      .put(`user/${userId}`, { phone: phoneWppValue })
      .then(() => {
        userPhone = phoneWppValue;
        setDisplayWarningModel(false);
        handleWhatsappButton(true);
        setWhatsapp(true);
      })
      .catch((error) => console.log(error));
  };

  const handleWhatsappButton = (isCloseForm = false) => {
    if (!phoneInput || !nameInput) {
      if (!whatsapp) {
        setDisplayWarningModel(true);
      } else {
        setDisplayContactForm(true);
      }
    } else {
      if (isCloseForm) {
        setLoadingWpp(true);
        loadingWpp = true;
        setTimeout(setLoadingWppF, 1000);
        sendWhatsappMessage(phoneInput, nameInput, props.imgSrc);
      } else {
        setShowLoading(false);
        setDisplayContactForm(true);
      }
    }
  };

  const setLoadingWppF = () => {
    setLoadingWpp(false);
    loadingWpp = false;
  };

  return (
    <Container
      display={props.display ? "flex" : "none"}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      brandColor={brandColor}
      brandContrastColor={brandContrastColor}
    >
      <i
        onClick={() => {
          setWhatsappMessageSent(false);
          props.onCloseClick();
        }}
        className="fas fa-times close"
      ></i>
      <div className="screenshotImg">
        <img src={props.imgSrc} alt=""></img>
      </div>
      <div className="footer">
        <button
          className="footer-button whatsapp"
          onClick={() => handleWhatsappButton()}
        >
          <i className="fab fa-whatsapp"></i>
        </button>
        <button
          className="footer-button"
          onClick={() => handleSaveButtonClick()}
        >
          <i className="far fa-save"></i>
        </button>
      </div>

      <GenericModal
        display={whatsappMessageSent}
        onCloseClick={() => setWhatsappMessageSent(false)}
        arrowYPosition={"28vh"}
        arrowXPosition={"6vw"}
        mainContent={
          <>
            {loadingWpp ? (
              <>
                <div className="loadingClass">
                  <CircularProgress />
                </div>
              </>
            ) : (
              <>
                <h3>{"Mensagem Enviada"}</h3>
                <br />
                <p>{"Sua mensagem foi enviada com sucesso!"}</p>
              </>
            )}
          </>
        }
      ></GenericModal>

      <GenericModal
        display={displayWarningModel}
        onCloseClick={() => setDisplayWarningModel(false)}
        arrowYPosition={"28vh"}
        arrowXPosition={"6vw"}
        mainContent={
          <>
            <h3>{"Registre seu número de contato whatsapp"}</h3>
            <br />
            <label>Deixe seu Whatsapp</label>
            <input
              type="text"
              maskPlaceholder={null}
              className={isContactNumberValid !== false ? "" : "alert"}
              placeholder="Digite o numero de telefone"
              value={phoneWppValue}
              onChange={(e) => setPhoneWppValue(e.currentTarget.value)}
            />
            <br />

            <div className="buttons-div">
              <button onClick={setPhoneWppF}>Continuar</button>
            </div>
          </>
        }
      ></GenericModal>

      {displayContactForm && (
        <ModalBackground
          onClick={() => setDisplayContactForm(false)}
        ></ModalBackground>
      )}

      <GenericModal
        display={displayContactForm}
        onCloseClick={() => setDisplayContactForm(false)}
        arrowYPosition={"28vh"}
        arrowXPosition={"6vw"}
        mainContent={
          <>
            <h3>{"Digite os dados do Cliente"}</h3>
            <br />

            {!isNamePersonValid && (
              <small className="alert">Insira o nome do cliente</small>
            )}
            {isNamePersonValid && <label>Nome do cliente*</label>}
            <input
              id="inputbtn"
              type="text"
              className={isNamePersonValid ? "" : "alert"}
              onChange={(e) => setNameInput(e.currentTarget.value)}
              //value={contactData.name}
              placeholder="Digite o nome do cliente"
            />
            <br />
            {isContactNumberValid !== false && <label>Fone com DDD*</label>}
            {!isContactNumberValid && (
              <small className="alert">Insira o whatsapp do cliente</small>
            )}
            <InputMask
              id="inputbtn"
              type="text"
              mask={inputMask}
              maskPlaceholder={null}
              className={isContactNumberValid !== false ? "" : "alert"}
              onChange={(e) => setPhoneInput(e.currentTarget.value)}
              //value={contactData.phone}
              placeholder="Deixe o WhatsApp do cliente"
            />
            <br />
            <br />
            <div
              className="buttons-div"
              style={{
                float: "none",
              }}
            >
              {!showLoading && (
                <button
                  ref={refButton}
                  className="alternative-button"
                  style={{
                    fontSize: 11,
                    width: "100%",
                  }}
                  onClick={() =>
                    sendWhatsappMessage(phoneInput, nameInput, props.imgSrc)
                  }
                >
                  Enviar para WhatsApp
                  <i className="fab fa-whatsapp whatsapp-icon"></i>
                </button>
              )}
              {showLoading && (
                <button
                  className="alternative-button"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="buttonload"></div>
                </button>
              )}

              {/* {showLoadingDark && (
                  <button className="alternative-button">
                    <object type="image/svg+xml" data={loadingDarkGif}>
                      svg-animation
                    </object>
                  </button>
                )}
                {showLoading && (
                  <button>
                    <object type="image/svg+xml" data={loadingGif}>
                      svg-animation
                    </object>
                  </button>
                )} */}
            </div>
          </>
        }
      ></GenericModal>
    </Container>
  );
};

export default ScreenshotModal;
