import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const NavigationBar = (props) => {
    const history = useHistory()

    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const type = useSelector(state => state.loginReducer.user.type)

    const dashboard = () => {

        if (type) {
            
                return (
                    <>
                        <a onClick={() => history.push("/")} className={props.viewIndex === 1 ? "selected" : ""}>
                            <i className="fas fa-home"></i>
                        </a>
                        <a onClick={() => history.push("/novo-ambiente")} className={props.viewIndex === 2 ? "selected" : ""}>
                            <i className="fas fa-plus-circle"></i>
                        </a>
                        <a onClick={() => history.push("/minha-conta")} className={props.viewIndex === 3 ? "selected" : ""}>
                            <i className="fas fa-user-circle"></i>
                        </a>
                        {type == 'manager' || type == 'admin' ?
                            <a onClick={() => history.push("/relatorios")} className={props.viewIndex === 4 ? "selected" : ""}>
                                <i className="far fa-file-alt"></i>
                            </a>
                        : ""}
                    </>
                )        
        }
    };

    return (
        <Container backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor}>
            {dashboard()}
        </Container>
    )
}

export default NavigationBar