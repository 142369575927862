import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-dropdown";
// Styles
import { Container } from "./styles";
import "react-dropdown/style.css";

import * as contractActions from "../../../store/modules/contract/actions";

import { contractContext } from "../../../Contexts/contractContext";

import { DataGrid } from "@mui/x-data-grid";

import UserData from "./UserData";

import api from "../../../services/api";

const Users = (props) => {
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const { currentContract, setCurrentContract } = useContext(contractContext);
  const dispatch = useDispatch();

  const isContractUsersLoading = useSelector(
    (state) => state.contractReducer.contractUsers.isLoading
  );
  //const users = useSelector(state => state.contractReducer.contractUsers.data)
  const [users, setUsers] = useState([]);

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  let [filteredRowsFiltered, setFilteredRowsFiltered] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserStatus, setSelectedUserStatus] = useState();

  const [displayLoading, setDisplayLoading] = useState(true);

  //let today = new Date()
  //const [dateInitial, setDateInitial] = useState(`${today.getFullYear()}-${today.getMonth() < 10 ? "0" + today.getMonth() : today.getMonth}-01`)
  //const [dateFinal, setDateFinal] = useState(`${today.getFullYear()}-${today.getMonth() < 10 ? "0" + today.getMonth() : today.getMonth}-${today.getDate() < 10 ? "0" + today.getDate() : today.getDate() }`)

  const [dateInitial, setDateInitial] = useState("");
  const [dateFinal, setDateFinal] = useState("");

  const [type, setType] = useState("Todos");
  //const [managedUsers, setManagedUsers] = useState([])

  const [displayUserData, setDisplayUserData] = useState(false);

  const loadCurrentContractUsers = async () => {
    const respUsers = await api.get(`contract/${currentContract._id}/user`);

    respUsers && respUsers.data.success && setUsers(respUsers.data.users);
    setDisplayLoading(false);
  };

  useEffect(() => {
    currentContract && loadCurrentContractUsers();
  }, [currentContract]);

  const formatDate = (pDate) => {
    let date = new Date(pDate);
    return date.toLocaleString("pt-BR");
  };

  const sortList = (pList) => {
    const helper = [...pList];

    return helper.sort(function (a, b) {
      if (a.createdAtOriginal < b.createdAtOriginal) {
        return 1;
      }
      if (a.createdAtOriginal > b.createdAtOriginal) {
        return -1;
      }

      return 0;
    });
  };

  useEffect(() => {
    if (users.length > 0) {
      let rowsHelper = users.map((u) => {
        return {
          id: u._id,
          name: u.name,
          login: u.login,
          phone: u.phone,
          email: u.email,
          status: u.status == "active" ? "Ativo" : "Inativo",
          createdAt: formatDate(u.createdAt),
          createdAtOriginal: u.createdAt,
          type: u.type,
          profession: u.profession,
        };
      });

      setRows(rowsHelper);

      rowsHelper = sortList(rowsHelper);

      setFilteredRows(rowsHelper);
      setFilteredRowsFiltered(rowsHelper);
    }
  }, [users]);

  useEffect(() => {
    let filteredHelper = rows;

    if (type.label && type.value !== "Todos") {
      filteredHelper = filteredHelper.filter(
        (row) => row.type === type.label.toLowerCase()
      );
    }

    if (dateFinal && dateInitial) {
      filteredHelper = filteredHelper.filter((row) => {
        if (row) {
          let time = new Date(row.createdAtOriginal);

          if (
            time > new Date(dateInitial) &&
            time < new Date(`${dateFinal} 23:59:59`)
          ) {
            return row;
          }
        }
      });
    }

    setFilteredRows(sortList(filteredHelper));
    setFilteredRowsFiltered(sortList(filteredHelper));
  }, [type, dateFinal, dateInitial]);

  const options = ["Lead", "Manager", "Admin", "Professional", "Todos"];

  const columns = [
    { field: "id", headerName: "ID", width: 120, hide: true },
    { field: "name", headerName: "Nome", width: 160 },
    { field: "login", headerName: "Usuário", width: 130 },
    { field: "phone", headerName: "Fone", width: 130 },
    { field: "email", headerName: "E-mail", width: 160 },
    { field: "status", headerName: "Ativo", width: 110 },
    { field: "createdAt", headerName: "Data de Cadastro", width: 200 },
    {
      field: "createdAtOriginal",
      headerName: "Data de Cadastro",
      width: 200,
      hide: true,
    },
    { field: "type", headerName: "Tipo", width: 110 },
    { field: "profession", headerName: "Profissão", width: 110 },
  ];

  const handleRowClick = (row) => {
    setDisplayUserData(true);

    setSelectedUser({
      id: row.id,
      name: row.name,
      status: row.status == "Ativo" ? "active" : "inactive",
      email: row.email,
      phone: row.phone,
      type: row.type,
      login: row.login,
      password: row.passowrd,
      passwordCheck: row.passwordCheck,
      editing: "update",
    });
  };

  const handleNewButton = () => {
    setDisplayUserData(true);

    setSelectedUser({
      id: "",
      name: "",
      status: "active",
      email: "",
      phone: "",
      login: "",
      password: "",
      passwordCheck: "",
      editing: "create",
    });
  };

  const handleTypeChange = (type) => {
    setType(type);
  };

  const handleExport = () => {
    let csv = "";

    let csvRows = filteredRows.map((item) => {
      let date = new Date(item.createdAt);
      let final_date = `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
      return {
        nome: item.name,
        login: item.login,
        telefone: item.phone,
        email: item.email,
        status: item.status,
        registrado: item.createdAt,
        tipo: item.type,
      };
    });
    let header = Object.keys(csvRows[0]).join(",");
    let values = csvRows.map((o) => Object.values(o).join(",")).join("\n");

    csv += header + "\n" + values;
    exportCSVFile(csv);
  };

  const exportCSVFile = (csv) => {
    let dateRange = `${dateInitial ? dateInitial : ""}/${
      dateFinal ? dateFinal : ""
    }`;
    let nameFormat = `${type} - ${dateRange === "/" ? "all time" : dateRange}`;
    let exportedFilename =
      "USUÁRIOS -" + " " + nameFormat + ".csv" || "export.csv";

    let link = document.createElement("a");
    if (link.download !== undefined) {
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv)
      );
      link.setAttribute("download", exportedFilename);
      link.click();
    }
  };

  const filterRow = (a) => {
    var list = new Array();

    for (const i of filteredRows) {
      if (
        i.name.includes(a) ||
        i.login.includes(a) ||
        i.phone.includes(a) ||
        i.email.includes(a)
      ) {
        list.push(i);
      }
    }

    setFilteredRowsFiltered(list);
    filteredRowsFiltered = list;
  };

  return (
    <Container
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      brandColor={brandColor}
    >
      <div className="title-new">
        <div className="title-left-side">
          <h1>Usuários</h1>
          <button className="new-btn" onClick={handleNewButton}>
            Novo
          </button>
        </div>
      </div>

      {displayUserData ? (
        <UserData
          selectedUser={selectedUser}
          onCloseClick={() => setDisplayUserData(false)}
        />
      ) : (
        <>
          <div className="filtros">
            <form className="form search">
              {/*<label>Pesquisar</label>*/}
              <input
                type="text"
                placeholder="Pesquisar"
                onChange={(event) => {
                  filterRow(event.target.value);
                }}
              />
            </form>

            <div className="type-container">
              <label>Tipo</label>
              <Dropdown
                className="dropTipos"
                options={options}
                onChange={handleTypeChange}
                value={type}
                placeholder="Selecione o tipo"
              />
            </div>

            <form className="form">
              <label>Início</label>
              <input
                type="date"
                value={dateInitial}
                onChange={(event) => {
                  setDateInitial(event.target.value);
                }}
              />
            </form>

            <form className="form">
              <label>Final</label>
              <input
                type="date"
                value={dateFinal}
                onChange={(event) => setDateFinal(event.target.value)}
              />
            </form>

            <div className="header-buttons">
              <button className="export-btn" onClick={handleExport}>
                Exportar
              </button>
            </div>
          </div>

          <div className="userList">
            <DataGrid
              rows={filteredRowsFiltered}
              columns={columns}
              pageSize={30}
              rowHeight={42}
              headerHeight={44}
              footerHeight={44}
              rowsPerPageOptions={[30]}
              onRowClick={(e) => handleRowClick(e.row)}
              hideFooterSelectedRowCount
              loading={displayLoading}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default Users;
