import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as contractActions from './../../../../store/modules/contract/actions'

// Styles
import { CamerasList, Warn } from './styles'

const Cameras = (props) => {
  const [cameras, setCameras] = useState()

  const isRoomsLoading = useSelector(state => state.contractReducer.contractRooms.isLoading)
  const rooms = useSelector(state => state.contractReducer.contractRooms.data)

  const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)

  const dispatch = useDispatch()

  useEffect(() => {
    props.contractId && isRoomsLoading && dispatch(contractActions.getContractRooms(props.contractId))
  }, []);

  useEffect(() => {
    const currentRoom = rooms.filter(item => String(item._id) === String(props.roomId))
    setCameras(currentRoom.cameras)
  },[rooms])

  const handleCameraClick = (camera) => {
    //Passar camera.Rotacao e camera.Posicao para o playcanvas
    props.setModal(false)
  }

  return (
    <>
      {cameras && cameras.length > 0 ? (
      <CamerasList backgroundColor={backgroundColor}>
      {cameras.map((camera, index) => (
        <li key={index} onClick={() => handleCameraClick(camera)}>
          <img loading="eager" src={camera.thumbPath} alt={`Vista ${camera.sequence}`} />
          <div className="card-shadow"></div>
          <h2>{`Vista ${camera.sequence}`}</h2>
        </li>
      ))}
      </CamerasList>
      ) : (
        <Warn>
          <h1>Nenhuma câmera disponível.</h1>
        </Warn>
      )}
    </>
  )
}

export default Cameras