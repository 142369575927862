import { call, put, all, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import * as types from "../types";
import api from "../../../services/api";

function* getContract({ payload }) {
  try {
    const response = yield call(api.get, `contract/user/${payload}`);
    //const response = yield call(api.get, 'contract?url=' + window.location.hostname.replace('www.', ''))
    yield put(actions.getContractSuccess(response));
  } catch (e) {
    console.log(e);
  }
}

function* getAllContracts({ payload }) {
  try {
    const response = yield call(api.get, `contract/reduced`);
    yield put(actions.getAllContractsSuccess(response));
  } catch (e) {
    console.log(e);
  }
}

function* getContractRooms({ payload }) {
  try {
    const response = yield call(api.get, "room/contract/" + payload);
    yield put(actions.getContractRoomsSuccess(response));
  } catch (e) {
    console.log(e);
  }
}

function* getContractMaterials({ payload }) {
  try {
    const response = yield call(
      api.get,
      "material/contract/" + payload + "/reduced?active=true"
    );
    yield put(actions.getContractMaterialsSuccess(response));
  } catch (e) {
    console.log(e);
  }
}

function* getContractTemplates({ payload }) {
  try {
    const response = yield call(api.get, "template/contract/" + payload);
    yield put(actions.getContractTemplatesSuccess(response));
  } catch (e) {
    console.log(e);
  }
}

function* getContractUsers({ payload }) {
  try {
    const response = yield call(api.get, `contract/${payload}/user`);
    yield put(actions.getContractUsersSuccess(response));
  } catch (e) {
    console.log(e);
  }
}

export default all([
  takeEvery(types.GET_CONTRACT, getContract),
  takeEvery(types.GET_ALL_CONTRACTS, getAllContracts),
  takeEvery(types.GET_CONTRACT_ROOMS, getContractRooms),
  /* takeEvery(types.GET_CONTRACT_MATERIALS, getContractMaterials), */
  takeEvery(types.GET_CONTRACT_TEMPLATES, getContractTemplates),
  takeEvery(types.GET_CONTRACT_USERS, getContractUsers),
]);
