import { call, put, all, takeEvery } from 'redux-saga/effects'
import * as actions from './actions'
import * as types from '../types'
import api from '../../../services/api'

function* getAllRooms ({payload}) {
    try {
        const response = yield call (api.get, '/room')
        yield put (actions.getAllRoomsSuccess(response))
    } catch (e) {
        console.log(e)
    }
}

function* addRoom ({payload}) {
    try {
        const response = yield call (api.post, '/room', payload) //check url later
        yield put (actions.addRoomSuccess(response))
    } catch (e) {
        console.log(e)
    }
}

function* editRoom ({payload}) {
    try {
        const response = yield call (api.put, '/room', payload) //check url later
        yield put (actions.editRoomSuccess(response))
    } catch (e) {
        console.log(e)
    }
}

// function* addRoomToContracts({payload}) {
//     try {
//         const response = yield call (api.put, `/contract/room/${payload.room_id}`)
//         yield put (actions.addRoomToContractsSuccess(response))
//     } catch (e) {
//         console.log(e)
//     }
// }

export default all([
    takeEvery(types.GET_ALL_ROOMS, getAllRooms),
    takeEvery(types.ADD_ROOM, addRoom),
    takeEvery(types.EDIT_ROOM, editRoom)
    // takeEvery(types.ADD_ROOM_TO_CONTRACTS, addRoomToContracts)
])