import React from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

import selectFaceIcon from './../../assets/pincel-preto.png'

const TipsModal = (props) => {
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    return (
        <Container 
            arrowUp={props.arrowUp}
            arrowDown={props.arrowDown}
            arrowLeft={props.arrowLeft}
            arrowYPosition={props.arrowYPosition}
            arrowXPosition={props.arrowXPosition}
            xPosition={props.xPosition}
            backgroundColor={backgroundColor}
            fontColor={fontColor}
            brandColor={brandColor}
        >
            
            <i className="fas fa-arrow-up"></i>

            <i className="fas fa-arrow-left"></i>

            <div className="modal-card">
                <div className="modal-card-title">
                    {props.showSelectFaceIcon && <img src={selectFaceIcon}></img>}
                    <h3>{props.title}</h3>
                </div>
                <p>{props.description}</p>
            </div>

            <i className="fas fa-arrow-down"></i>
            
        </Container>
    )
}

export default TipsModal