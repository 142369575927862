import React from 'react'
import { useSelector } from 'react-redux'

import { ContainerTop, ContainerBottom } from './styles'

const ModalBackgroundHelper = () => {
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    return (
        <>
            <ContainerTop fontColor={fontColor}></ContainerTop>
            <ContainerBottom fontColor={fontColor}></ContainerBottom>
        </>
    )
}

export default ModalBackgroundHelper