import * as types from '../types'

export function getAllRooms(payload) {
    return {
        type: types.GET_ALL_ROOMS,
        payload,
    }
}

export function getAllRoomsSuccess(payload) {
    return {
        type: types.GET_ALL_ROOMS_SUCCESS,
        payload
    }
}

export function addRoom(payload) {
    return {
        type: types.ADD_ROOM,
        payload
    }

}

export function addRoomSuccess(payload) {
    return {
        type: types.ADD_ROOM_SUCCESS,
        payload
    }
}

export function editRoom(payload) {
    return {
        type: types.EDIT_ROOM,
        payload
    }

}

export function editRoomSuccess(payload) {
    return {
        type: types.EDIT_ROOM_SUCCESS,
        payload
    }
}


// export function addRoomToContracts(payload) {
//     return {
//         type: types.ADD_ROOM_TO_CONTRACTS,
//         payload
//     }
// }

// export function addRoomToContractsSuccess(payload) {
//     return {
//         type: types.ADD_ROOM_TO_CONTRACTS_SUCCESS,
//         payload
//     }
// }