import React, { useState } from 'react'
import api from '../../../../../../services/api'

import { useSelector } from 'react-redux'

import { Container } from './styles'

const AllThumb = (props) => {
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const Type = useSelector(state => state.loginReducer.user.type)

    const [statusState, setStatusState] = useState(props.status)

    const handleStatus = (orderId, status) => {

        api
            .put(`order/${orderId}`, {
                "status": status
            })
            .then((data) => {
                setStatusState(status)
            })
            .catch((err) => console.log(err))
    }

    const status = (props) => {
        if (statusState == 'open') {
            return (
                <>
                    <div className="open">
                        <a>Em Aberto <i className="fas fa-chevron-down"></i></a>

                        <div className="menuContainerOpen">
                            <div onClick={() => handleStatus(props.id, 'closed')} className="content">
                                <a>Venda Concluída</a>
                            </div>
                            <div onClick={() => handleStatus(props.id, 'cancelled')} className="content">
                                <a>Venda Perdida</a>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (statusState == 'closed') {
            return (
                <>
                    <div className="closed">
                        <a>Venda Concluída <i className="fas fa-chevron-down"></i></a>

                        <div className="menuContainerClosed">
                            <div onClick={() => handleStatus(props.id, 'cancelled')} className="content">
                                <a>Venda Perdida</a>
                            </div>
                            <div onClick={() => handleStatus(props.id, 'open')} className="content">
                                <a>Em Aberto</a>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="cancelled">
                        <a>Venda Perdida <i className="fas fa-chevron-down"></i></a>

                        <div className="menuContainerCancelled">
                            <div onClick={() => handleStatus(props.id, 'closed')} className="content">
                                <a>Venda Concluída</a>
                            </div>
                            <div onClick={() => handleStatus(props.id, 'open')} className="content">
                                <a>Em Aberto</a>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    const statusModif = () => {
            if (Type && Type != 'lead') {
                return (
                    <>
                        <img src={props.imgSrc ? props.imgSrc : 'https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/template/banheiro-1.jpg'} alt="" />
                        <div onClick={props.onThumbClick} className="card-shadow"></div>
                        <h4>{props.title}</h4>
                        <h4 className="name">{props.name}</h4>
                        <h4 className="status">{status(props)}</h4>
                    </>
                )
            } else {
                return (
                    <>
                        <img src={props.imgSrc ? props.imgSrc : 'https://d3j9qmbv5hjp0y.cloudfront.net/cria-decor/assets/img/template/banheiro-1.jpg'} alt="" />
                        <div onClick={props.onThumbClick} className="card-shadow"></div>
                        <h4 className="Lead">{props.title}</h4>
                    </>
                )
            }
        
    }



    return (
        <Container backgroundColor={backgroundColor}>
            {statusModif()}
        </Container>
    )

}

export default AllThumb