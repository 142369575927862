import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Styles
import { Container, Header } from './styles'

const PrivacyPolicy = () => {
    const [innerHeight, setInnerHeight] = useState(window.innerHeight)

    const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)
    const alertColor = useSelector(state => state.contractReducer.contract.config.alertColor)
    const logoPath = useSelector(state => state.contractReducer.contract.config.logoPath)

    const history = useHistory()

    useEffect(() => {
        const handleResize = () => setInnerHeight(window.innerHeight)
        window.addEventListener("resize", handleResize)
    
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return (
        <Container innerHeight={innerHeight} backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor} brandContrastColor={brandContrastColor} alertColor={alertColor}>
            <Header>
                <img src={logoPath} alt="Logotipo" onClick={() => history.push("/login")}></img>
            </Header>
            <h2>Política de Privacidade</h2>
            <p>
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
                Colocar o texto da política de privacidade aqui neste lugar.
            </p>
        </Container>
    )
}

export default PrivacyPolicy