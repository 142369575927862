import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel, { consts } from "react-elastic-carousel";

import * as userRoomsActions from "./../../../../store/modules/userRooms/actions";

// Styles
import { Container, ContainerRooms, Filters } from "./styles";

// Components
import OrderThumb from "../OrderThumb";

import FormControl from "@material-ui/core/FormControl";
import Dropdown from "react-dropdown";

import Loader from "../../../../components/Loader";

const MyOrders = (props) => {
  const isMyOrdersLoading = useSelector(
    (state) => state.userRoomsReducer.isLoading
  );
  const myOrders = useSelector((state) => state.userRoomsReducer.rooms);

  const userId = useSelector((state) => state.loginReducer.user.id);
  const type = useSelector((state) => state.loginReducer.user.type);
  const contractId = useSelector(
    (state) => state.contractReducer.contract.contractId
  );
  const isContractLoading = useSelector(
    (state) => state.contractReducer.contract.isLoading
  );

  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const dispatch = useDispatch();

  const [sortedOrders, setSortedOrders] = useState([]);

  const [statusFilter, setstatusFilter] = useState("Todos");

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().toLocaleDateString("pt-BR", {
    month: "2-digit",
  });
  const currentDay = new Date().toLocaleDateString("pt-BR", { day: "2-digit" });

  const [dateInitial, setDateInitial] = useState(
    `${currentYear}-${currentMonth}-01`
  );
  const [dateFinal, setDateFinal] = useState(
    `${currentYear}-${currentMonth}-${currentDay}`
  );

  const compare = (a, b) => {
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    return 0;
  };

  const sortArray = (orderArray) => {
    return orderArray.sort(compare);
  };

  useEffect(() => {
    myOrders && setSortedOrders(sortArray(myOrders));
  }, [myOrders]);

  useEffect(() => {
    if (sortedOrders) {
      if (props.type === "Em Aberto") {
        const filteredOrders = myOrders.filter((item) => item.status == "open");
        setSortedOrders(sortArray(filteredOrders));
      } else if (props.type === "Venda Concluída") {
        const filteredOrders = myOrders.filter(
          (item) => item.status == "closed"
        );
        setSortedOrders(sortArray(filteredOrders));
      } else if (props.type === "Venda Perdida") {
        const filteredOrders = myOrders.filter(
          (item) => item.status == "cancelled"
        );
        setSortedOrders(sortArray(filteredOrders));
      } else {
        const filteredOrders = myOrders.filter(
          (item) => item.status != "deleted"
        );
        setSortedOrders(sortArray(filteredOrders));
      }
    }
  }, [props.type]);

  useEffect(() => {
    isMyOrdersLoading &&
      !isContractLoading &&
      dispatch(
        userRoomsActions.getUserRooms({ user: userId, contract: contractId })
      );
  }, [isContractLoading, isMyOrdersLoading]);

  const onOrderThumbClick = (order) => {
    let state = {
      roomModelId: order.room._id,
      playcanvasId: order.room.playCanvas,
      roomCameras: order.room.cameras,
      orderId: order._id,
      orderTitle: order.title,
      orderStatus: order.status,
      orderImageKey: order.images[0].imageKey,
      showInspirationModal: false,
    };

    if (type && type != "lead") {
      state.orderName = order.customer.name;
      state.orderPhone = order.customer.phone;
    }

    props.history.push({
      pathname: "/simulador",
      state: state,
    });
  };

  const StatusOptions = [
    "Todas",
    "Venda Concluída",
    "Em Aberto",
    "Venda Perdida",
  ];

  useEffect(() => {
    let myOrdersHelper = myOrders.filter((item) => item.status != "deleted");

    if (statusFilter === "Em Aberto")
      myOrdersHelper = myOrders.filter((item) => item.status == "open");
    else if (statusFilter === "Venda Concluída")
      myOrdersHelper = myOrders.filter((item) => item.status == "closed");
    else if (statusFilter === "Venda Perdida")
      myOrdersHelper = myOrders.filter((item) => item.status == "cancelled");

    if (myOrdersHelper && dateFinal && dateInitial) {
      myOrdersHelper = myOrdersHelper.filter((row) => {
        if (row) {
          let time = new Date(row.createdAt);

          if (
            time > new Date(dateInitial) &&
            time < new Date(`${dateFinal} 23:59:59`)
          ) {
            return row;
          }
        }
      });
    }

    setSortedOrders(sortArray(myOrdersHelper));
  }, [myOrders, statusFilter, dateFinal, dateInitial]);

  return (
    <>
      <Filters>
        <form className="status-selector">
          <label>Situação</label>
          <Dropdown
            className="dropStatusFilter"
            options={StatusOptions}
            onChange={(e) => setstatusFilter(e.value)}
            value={statusFilter}
            placeholder="Selecione o Contrato"
          />
        </form>

        <form className="form">
          <label>Data inicial</label>
          <input
            type="date"
            value={dateInitial}
            onChange={(event) => {
              setDateInitial(event.target.value);
            }}
          />
        </form>

        <form className="form">
          <label>Data final</label>
          <input
            type="date"
            value={dateFinal}
            onChange={(event) => setDateFinal(event.target.value)}
          />
        </form>
        <button
          className="btn-new-order"
          onClick={() => props.history.push("/novo-ambiente")}
        >
          <i className="fas fa-plus-circle"></i>
          Novo Atendimento
        </button>
      </Filters>
      <Container>
        {isMyOrdersLoading ? (
          <Loader borderRadius={"15px"} />
        ) : sortedOrders.length <= 0 ? (
          <ContainerRooms
            backgroundColor={backgroundColor}
            fontColor={fontColor}
          >
            <p className="text">Você ainda não possui atendimentos</p>
          </ContainerRooms>
        ) : (
          sortedOrders.map((room) => (
            <OrderThumb
              key={room._id}
              id={room._id}
              playCanvasId={room.PlaycanvasId}
              title={room.title}
              status={room.status}
              name={room.customer.name}
              imgSrc={room.images[0].imageSrc}
              onThumbClick={() => onOrderThumbClick(room)}
              showDetails={true}
            ></OrderThumb>
          ))
        )}
      </Container>
    </>
  );
};

export default MyOrders;
