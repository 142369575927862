import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  width: 90vw;
  border-radius: 8px;
  margin: 0 .2rem 1.4rem .2rem;
  cursor: pointer;

  @media only screen and (min-width: 1024px) {
    width: 22vw;
    height: fit-content;
    margin: 0 .6rem 1.4rem .6rem;

    img {
      width: 22vw !important;
      height: 16.5vw !important;
    }
  }

  img {
    position: relative;
    border-radius: 8px;
    width: 90vw;
    height: 67.5vw;
    object-fit: cover;
  }

  div.card-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 8px;
    background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.9));
    bottom: 0;
  }

  h4 {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin: .6rem .8rem 1.6rem .8rem;
    bottom: 0;
  }

  h4.Lead {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin: .6rem .8rem .6rem .8rem;
    bottom: 0;
  }

  h4.status {
    position: absolute;
    font-size: 10px;
    color: ${props => props.backgroundColor};
    margin: .6rem .8rem 3.3rem .8rem;
    bottom: 0;
  }

  h4.name{
    position: absolute;
    font-size: 10px;
    color: #fff;
    margin: .6rem .8rem .6rem .8rem;
    bottom: 0;
  }

  i{
    transform: rotate(-90deg);
    transition: 0.3s;
  }

 //---------------------------------------//
  div.open{
    width: 98px;
    height: 20px;
    background: orange;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  div.open:hover{
    border-radius: 8px 8px 0px 0px;
  }

  div.open:hover i{
    transform: rotate(0deg);
  }

  div.menuContainerOpen {
    border-radius: 0px 0px 8px 8px;
    height: 62px;
    width: 98px;
    position: absolute;
    margin-top: 80px;
    background-color: orange;
    padding-top: 5px;
    flex-direction: column;
    opacity: 0;
    transition: 0.3s;
  }

  div.open:hover div.menuContainerOpen{
    opacity: 1;
  }

//---------------------------------------//
  div.closed{
    width: 115px;
    height: 20px;
    background: green;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  div.closed:hover{
    border-radius: 8px 8px 0px 0px;
  }

  div.closed:hover i{
    transform: rotate(0deg);
  }

  div.menuContainerClosed {
    border-radius: 0px 0px 8px 8px;
    height: 63px;
    width: 115px;
    position: absolute;
    margin-top: 80px;
    background-color: green;
    padding-top: 5px;
    flex-direction: column;
    opacity: 0;
    transition: 0.3s;
  }

  div.closed:hover div.menuContainerClosed{
    opacity: 1;
  }

//---------------------------------------//
  div.cancelled{
    width: 105px;
    height: 20px;
    background: red;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.cancelled:hover{
    border-radius: 8px 8px 0px 0px;
  }

  div.cancelled:hover i{
    transform: rotate(0deg);
  }

  div.menuContainerCancelled {
    border-radius: 0px 0px 8px 8px;
    height: 62px;
    width: 105px;
    position: absolute;
    margin-top: 78px;
    background-color: red;
    padding-top: 5px;
    flex-direction: column;
    opacity: 0;
    transition: 0.3s;
  }

  div.cancelled:hover div.menuContainerCancelled{
    opacity: 1;
  }

//---------------------------------------//
  div.content{
    display: flex;
    width: 100%;
    height: 25px;
    align-items: center;
    padding-left: 5px;
  }

  div.content:hover{
    background: rgba(0,0,0,.2);
  }

  a {
    color: #fff;
    font-weight: bold;
  }
`